import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import { httpClient } from "../../utils/HttpClient";
import { API, LOGIN_TOKEN } from "../../Constants";
import SaveAsIcon from "@mui/icons-material/SaveAs";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import { Box, TextField } from "@mui/material";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
// Table
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Callback from "../snackbar/Callback";
//

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export default function CustomizedDialogs({
  open,
  type,
  target_id,
  AddedData,
  handleClose,
  parentReloadData,
}) {
  const [ItemList, setItemList] = useState([]);
  const [ItemListOldData, setItemListOldData] = useState([]);
  const [isCheck, setIsCheck] = useState([]);
  const [isCheckAll, setIsCheckAll] = useState(false);
  const [CallbackData, setCallbackData] = React.useState({
    open: false,
    type: "success",
    message: "",
  });

  const LoadFolders = async () => {
    const res = await httpClient.get(`${API.URL}/v1/${type}/folders`, {
      headers: {
        authorization: localStorage.getItem(LOGIN_TOKEN),
      },
    });
    if (res.data.status === "success") {
      setItemList(res.data.message.data);
    }
  };

  const handleSelectAll = (e) => {
    setIsCheckAll(!isCheckAll);

    setIsCheck(ItemList.map((li) => parseInt(li.folder_id)));
    if (isCheckAll) {
      setIsCheck([]);
    }
  };

  const searchData = (text) => {
    // filter ItemList by text
    let newData = [];
    if (ItemListOldData.length < 1) {
      setItemListOldData(ItemList);
      newData = ItemList.filter((item) =>
        item.folder_name.toLowerCase().includes(text.toLowerCase())
      );
    } else {
      newData = ItemListOldData.filter((item) =>
        item.folder_name.toLowerCase().includes(text.toLowerCase())
      );
    }

    setItemList(newData);
  };

  const handleChange = (e) => {
    const id = parseInt(e.target.getAttribute("item_id"));
    const checked = e.target.checked;
    setIsCheck([...isCheck, id]);
    if (!checked) {
      setIsCheck(isCheck.filter((item) => item !== id));
    }
  };

  const handleChangeBox = (checked, item_id) => {
    setIsCheck([...isCheck, item_id]);
    if (checked) {
      setIsCheck(isCheck.filter((item) => item !== item_id));
    }
  };

  const handleSave = async () => {
    console.log({ folders: isCheck, type: type, target_id: target_id });
    const res = await httpClient.put(
      `${API.URL}/auth/category/folder`,
      { folders: isCheck, target_id: target_id, folder_type: type },
      {
        headers: {
          authorization: localStorage.getItem(LOGIN_TOKEN),
        },
      }
    );
    if (res.data.status === "success") {
      setCallbackData({
        ...CallbackData,
        open: true,
        type: "success",
        message: res.data.message,
      });
      parentReloadData();
      // handleClose();
    } else {
      console.log(res.data.message);
    }
  };

  const syncAddedList = () => {
    setIsCheck(AddedData.map((item) => parseInt(item.folder_id)));
  };

  useEffect(() => {
    if (open) {
      LoadFolders();
      syncAddedList();
    }
  }, [open]);

  return (
    <div>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        maxWidth="md"
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
        >
          เลือกเพิ่ม Folder {type.toUpperCase()} ให้กับหมวดหมู่
        </BootstrapDialogTitle>
        <DialogContent dividers sx={{ width: "fit-content" }}>
          <Box sx={{ my: 2 }}>
            <TextField
              label="ค้นหา"
              size="small"
              type="search"
              variant="outlined"
              defaultValue=""
              onKeyUp={(e) => searchData(e.target.value)}
            />
          </Box>
          <Box>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow sx={{ bgcolor: "#e3e3e3" }}>
                  <TableCell align="center">
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={isCheckAll}
                          onChange={handleSelectAll}
                        />
                      }
                      label="เลือกทั้งหมด"
                    />
                  </TableCell>
                  <TableCell align="left">ชื่อเรียก/ชื่อที่ปรากฏ</TableCell>
                  <TableCell align="center">จำนวน Item</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {ItemList.length > 0 &&
                  ItemList.map((item) => (
                    <TableRow
                      key={item.folder_id}
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                        "&: hover": { bgcolor: "#f3f3f3" },
                        whiteSpace: "normal",
                      }}
                    >
                      <TableCell align="center">
                        <Checkbox
                          checked={isCheck.includes(parseInt(item.folder_id))}
                          onChange={handleChange}
                          inputProps={{ item_id: item.folder_id }}
                        />
                      </TableCell>
                      <TableCell align="left">
                        <Box
                          sx={{ minWidth: "400px", cursor: "pointer" }}
                          onClick={() => {
                            handleChangeBox(
                              isCheck.includes(parseInt(item.folder_id)),
                              parseInt(item.folder_id)
                            );
                          }}
                        >
                          {item.folder_name}
                          <br />
                          <span style={{ color: "gray" }}>
                            {item.folder_title}
                          </span>
                        </Box>
                      </TableCell>
                      <TableCell align="center">{item.folder_count}</TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleSave} variant="contained">
            <SaveAsIcon sx={{ mr: 1 }} />
            บันทึกการเปลี่ยนแปลง
          </Button>
        </DialogActions>
      </BootstrapDialog>
      <Callback
        {...CallbackData}
        close={() => {
          setCallbackData({ ...CallbackData, open: false });
        }}
      />
    </div>
  );
}
