import { Box, Button, Grid, Paper, Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { API, LOGIN_TOKEN } from "../../../Constants";
import { httpClient } from "../../../utils/HttpClient";
import Iframe from "react-iframe";

// Breadcrumb
import CustomizedBreadcrumbs from "../../template/Breadcrumb";
//
// icon
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import LocalOfferIcon from "@mui/icons-material/LocalOffer";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import SaveAsIcon from "@mui/icons-material/SaveAs";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
//
import TextField from "@mui/material/TextField";
import { Form, useFormik } from "formik";
import * as yup from "yup";
// Table
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Pagination from "@mui/material/Pagination";
//

// Dialog
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Callback from "../../snackbar/Callback";
import ConfirmDialog from "../../disalog/Confirm";
//

const ItemDataInit = {
  item_id: null,
  item_name: "",
  item_value: "",
};

const ConfirmDialogDataInit = {
  open: false,
  data: {
    type: "confirm",
    title: "ยืนยันการลบ!",
    message: "ท่านต้องการลบรายการนี้หรือไม่",
  },
  returnData: {
    action: "",
    target: "",
    id: null,
  },
};

function PdfEdit() {
  const navigate = useNavigate();
  const { id } = useParams();
  const [FolderData, setFolderData] = useState({
    folder_id: null,
    parent_id: null,
    folder_name: "",
    folder_title: "",
    folder_count: null,
    folder_type: null,
    folder_create_at: null,
    folder_update_at: null,
  });
  // Breadcrumb
  const pageName = "PDF (ANYFLIP)";
  const breadCrumb = [
    { path: "/pdf/folders", name: "PDF (ANYFLIP)" },
    {
      path: `/pdf/folders/id/${id}`,
      name: `Folder : ${FolderData.folder_name}`,
    },
  ];
  //

  const [ItemData, setItemData] = useState(ItemDataInit);

  const [ItemList, setItemList] = useState({
    total: 0,
    per_page: 0,
    data: [],
  });
  // Paginate
  const [TablePage, setTablePage] = useState(1);

  const [CallbackData, setCallbackData] = useState({
    open: false,
    type: "success",
    message: "TEST CALLBACK",
    duration: 2000,
  });
  const [ConfirmDialogData, setConfirmDialogData] = useState(
    ConfirmDialogDataInit
  );

  const [TablePerPage, setTablePerPage] = useState(30);
  const TablePageChange = async (e, val) => {
    setTablePage(val);
    setReqParams({ ...ReqParams, page: val });
  };
  const [ReqParams, setReqParams] = useState({
    type: "pdf",
    page: TablePage,
    perpage: TablePerPage,
  });
  // Using : new URLSearchParams( ReqParams ).toString();
  //

  const [AddItemOpen, setAddItemOpen] = React.useState(false);

  const AddItemHandleClickOpen = () => {
    setAddItemOpen(true);
  };

  const AddItemHandleClose = () => {
    setAddItemOpen(false);
  };

  const validationSchema = yup.object({
    folder_name: yup
      .string("กรุณากรอกข้อมูล")
      .min(5, "Require 5+")
      .required("is required"),
    folder_title: yup
      .string("กรุณากรอกข้อมูล")
      .min(5, "Require 5+")
      .required("is required"),
  });

  const formik = useFormik({
    initialValues: FolderData,
    enableReinitialize: true,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      console.log(values);
    },
  });

  const loadFolderData = async () => {
    const res = await httpClient.get(
      `${API.URL}/auth/pdf/folders?folder_id=${id}`,
      {
        headers: {
          Authorization: localStorage.getItem(LOGIN_TOKEN),
        },
      }
    );
    if (res.data.status === "success") {
      setFolderData(res.data.message);
    } else {
      console.log(res.data.message);
    }
  };

  const loadFolderItems = async () => {
    const res = await httpClient.get(
      `${API.URL}/auth/pdf/folder_items?folder_id=${id}`,
      {
        headers: {
          Authorization: localStorage.getItem(LOGIN_TOKEN),
        },
      }
    );
    if (res.data.status === "success") {
      setItemList(res.data.message);
    } else {
      console.log(res.data.message);
    }
  };

  const saveFolderData = async () => {
    const res = await httpClient.put(
      `${API.URL}/auth/folder/put`,
      {
        folder_id: id,
        folder_name: formik.values.folder_name,
        folder_title: formik.values.folder_title,
      },
      {
        headers: {
          Authorization: localStorage.getItem(LOGIN_TOKEN),
        },
      }
    );
    if (res.data.status === "success") {
      setCallbackData({
        ...CallbackData,
        open: true,
        type: "success",
        message: res.data.message,
      });
    } else {
      setCallbackData({
        ...CallbackData,
        open: true,
        type: "error",
        message: "ผิดพลาด...กรุณาแจ้งผู้ดูแลระบบ",
      });
      console.log(res.data);
    }
  };

  const addItem = async () => {
    const res = await httpClient.post(
      `${API.URL}/auth/pdf/put_item`,
      {
        folder_id: id,
        item_id: ItemData.item_id,
        item_name: ItemData.item_name,
        item_value: ItemData.item_value,
        item_type: FolderData.folder_type,
      },
      {
        headers: {
          Authorization: localStorage.getItem(LOGIN_TOKEN),
        },
      }
    );
    if (res.data.status === "success") {
      setCallbackData({
        ...CallbackData,
        open: true,
        type: "success",
        message: res.data.message,
      });
      setItemData(ItemDataInit);
      loadFolderItems();
    }
    AddItemHandleClose();
  };

  const getPdfCover = (link) => {
    if (link.indexOf("book") > 0) {
      if (link.indexOf("bookcase") > 0) {
        return process.env.PUBLIC_URL + "/images/icons/shelf.jpg";
      } else {
        const link_arr = link.split("/");
        const arrCount = link_arr.length;
        console.log(link_arr);
        return `https://online.anyflip.com/fsslo/${
          link_arr[arrCount - 1] === ""
            ? link_arr[arrCount - 2]
            : link_arr[arrCount - 1]
        }/files/shot.jpg`;
      }
    } else {
      return process.env.PUBLIC_URL + "/images/icons/link-error.jpg";
    }
  };

  const deleteFolder = async () => {
    const folder_id = id;
    const res = await httpClient.delete(
      `${API.URL}/auth/folder/delete/${folder_id}`,
      {
        headers: {
          Authorization: localStorage.getItem(LOGIN_TOKEN),
        },
      }
    );
    if (res.data.status === "success") {
      setCallbackData({
        ...CallbackData,
        open: true,
        type: "success",
        message: res.data.message,
      });
      setTimeout(() => {
        navigate(`${breadCrumb[0].path}`);
      }, 500);
    }
  };

  const deleteItem = async (id) => {
    console.log("DELETE ITEM " + id);
    const res = await httpClient.delete(`${API.URL}/auth/pdf/item/${id}`, {
      headers: {
        Authorization: localStorage.getItem(LOGIN_TOKEN),
      },
    });
    if (res.data.status === "success") {
      setCallbackData({
        ...CallbackData,
        open: true,
        type: "success",
        message: res.data.message,
      });
      loadFolderItems();
    } else {
      setCallbackData({
        ...CallbackData,
        open: true,
        type: "error",
        message: "พบข้อผิดพลาด... โปรดแจ้งผู้ดูแลระบบ.",
      });
      console.error(res.data);
    }
  };

  const ConfirmAction = () => {
    const action = ConfirmDialogData.returnData.action;
    const target = ConfirmDialogData.returnData.target;
    const id = ConfirmDialogData.returnData.id;
    console.log(`Action:${action} Target:${target} ID:${id}`);
    // delete
    if (action === "delete") {
      // Link
      if (target === "link") {
        deleteItem(id);
      } else if (target === "folder") {
        deleteFolder();
      }
    }
    setConfirmDialogData(ConfirmDialogDataInit);
  };

  useEffect(() => {
    loadFolderData();
    loadFolderItems();
  }, []);

  return (
    <>
      <CustomizedBreadcrumbs links={breadCrumb} />

      <Grid container>
        <Grid item xs={3}>
          <form onSubmit={formik.handleSubmit}>
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <img
                src={`${process.env.PUBLIC_URL}/images/folder.png`}
                alt=""
                style={{ width: "200px" }}
              />
            </Box>
            <TextField
              fullWidth
              label="ชื่อเรียก(ภายใน)"
              size="small"
              sx={{ mb: 2 }}
              id="folder_name"
              name="folder_name"
              InputLabelProps={{ shrink: true }}
              value={formik.values.folder_name}
              onChange={formik.handleChange}
              error={
                formik.touched.folder_name && Boolean(formik.errors.folder_name)
              }
              helperText={
                formik.touched.folder_name && formik.errors.folder_name
              }
            />
            <TextField
              fullWidth
              label="ชื่อที่ปรากฏหน้าเว็บ"
              size="small"
              sx={{ mb: 2 }}
              id="folder_title"
              name="folder_title"
              InputLabelProps={{ shrink: true }}
              value={formik.values.folder_title}
              onChange={formik.handleChange}
              error={
                formik.touched.folder_title &&
                Boolean(formik.errors.folder_title)
              }
              helperText={
                formik.touched.folder_title && formik.errors.folder_title
              }
            />
            <Typography variant="body1" color="secondary">
              <LocalOfferIcon /> ประเภท : {FolderData.folder_type}
            </Typography>
            <Typography variant="body1" color="secondary">
              <AccessTimeIcon /> สร้างเมื่อ : {FolderData.folder_create_at}
            </Typography>
            <Typography variant="body1" color="secondary">
              <AccessTimeIcon /> อัพเดทเมื่อ : {FolderData.folder_update_at}
            </Typography>
          </form>
          <Grid container sx={{ pt: 4 }}>
            <Grid item xs={6}>
              <Button
                variant="contained"
                color="danger"
                onClick={async () => {
                  setConfirmDialogData({
                    ...ConfirmDialogData,
                    open: true,
                    data: {
                      type: "confirm",
                      title: "ยืนยันการลบ!",
                      message:
                        "ท่านต้องการลบโฟลเดอร์นี้หรือไม่? โปรดทราบว่าเมื่อลบโฟลเดอร์ข้อมูลต่างๆภายในโฟลเดอร์จะถูกลบไปด้วย!",
                    },
                    returnData: {
                      action: "delete",
                      target: "folder",
                      id: null,
                    },
                  });
                }}
              >
                <DeleteForeverIcon />
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button
                variant="contained"
                color="primary"
                fullWidth
                onClick={() => {
                  saveFolderData();
                }}
              >
                <SaveAsIcon sx={{ mr: 1 }} /> บันทึกข้อมูล
              </Button>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={9}>
          <Paper elevation={1} sx={{ p: 2, ml: 2 }}>
            <Typography variant="h5" color="initial" sx={{ mb: 2 }}>
              {formik.values.folder_name}{" "}
              <small style={{ color: "#005c9c" }}>
                ({ItemList.data.length} รายการ)
              </small>
              <Button
                variant="contained"
                color="info"
                onClick={() => {
                  setItemData(ItemDataInit);
                  AddItemHandleClickOpen();
                }}
                sx={{ ml: 2 }}
              >
                <AddCircleOutlineIcon sx={{ mr: 1 }} />
                เพิ่ม
              </Button>
            </Typography>

            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow sx={{ bgcolor: "#e3e3e3" }}>
                  <TableCell>#</TableCell>
                  <TableCell align="left">ชื่อ</TableCell>
                  <TableCell align="center">สร้างเมื่อ</TableCell>
                  <TableCell align="center">ดูตัวอย่าง</TableCell>
                  <TableCell align="center">แก้ไข</TableCell>
                  <TableCell align="center">ลบ</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {ItemList.data.length > 0 &&
                  ItemList.data.map((row, index) => (
                    <TableRow
                      key={"item_" + row.item_id}
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                        "&: hover": { bgcolor: "#f3f3f3" },
                        whiteSpace: "normal",
                      }}
                    >
                      <TableCell>{index + 1}</TableCell>
                      <TableCell align="left">
                        <Typography
                          variant="body1"
                          color="initial"
                          sx={{
                            width: "220px",
                            whiteSpace: "normal",
                            wordWrap: "break-word",
                          }}
                        >
                          {row.item_name}
                        </Typography>
                      </TableCell>

                      <TableCell align="center">{row.item_create_at}</TableCell>
                      <TableCell align="center">
                        <a
                          href={row.item_value}
                          target="_blank"
                          rel="noreferrer"
                        >
                          <img
                            src={getPdfCover(row.item_value)}
                            alt=""
                            style={{
                              maxWidth: "100px",
                              maxHeight: "80px",
                              margin: "0 auto",
                              boxShadow: "0px 0px 3px #00000069",
                              padding: "5px",
                              borderRadius: "5px",
                            }}
                          />
                        </a>
                      </TableCell>
                      <TableCell align="center">
                        <Button
                          variant="contained"
                          color="secondary"
                          onClick={() => {
                            setItemData({
                              ...ItemData,
                              item_id: row.item_id,
                              item_name: row.item_name,
                              item_value: row.item_value,
                            });
                            setAddItemOpen(true);
                          }}
                        >
                          <ModeEditIcon />
                        </Button>
                      </TableCell>
                      <TableCell align="center">
                        <Button
                          color="danger"
                          onClick={async () => {
                            setConfirmDialogData({
                              ...ConfirmDialogData,
                              open: true,
                              returnData: {
                                action: "delete",
                                target: "link",
                                id: row.fi_id,
                              },
                            });
                          }}
                        >
                          <DeleteForeverIcon />
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
            {ItemList.data.length <= 0 && (
              <Box sx={{ textAlign: "center", p: 2 }}>
                <Typography variant="h6" color="GrayText">
                  ยังไม่มีข้อมูล.{" "}
                  <Button
                    variant="contained"
                    color="info"
                    onClick={() => {
                      setItemData(ItemDataInit);
                      AddItemHandleClickOpen();
                    }}
                  >
                    <AddCircleOutlineIcon sx={{ mr: 1 }} />
                    เพิ่ม
                  </Button>
                </Typography>
              </Box>
            )}
            {ItemList.data.length > 0 && (
              <Box sx={{ mt: 4 }}>
                <Pagination
                  count={parseInt(
                    Math.ceil(ItemList.total / ItemList.per_page)
                  )}
                  page={TablePage}
                  onChange={TablePageChange}
                  showFirstButton
                  showLastButton
                />
              </Box>
            )}
          </Paper>
        </Grid>
      </Grid>

      <ConfirmDialog
        {...ConfirmDialogData}
        handleClose={() => {
          setConfirmDialogData({ ...ConfirmDialogData, open: false });
        }}
        confirmed={(e) => {
          if (e === true) {
            //action
            ConfirmAction();
          }
        }}
      />

      <Dialog open={AddItemOpen} onClose={AddItemHandleClose}>
        <DialogTitle>
          {ItemData.item_id == null ? "เพิ่ม" : "แก้ไข"} Link PDF (Anyflip)
        </DialogTitle>
        <DialogContent>
          <DialogContentText sx={{ p: "5px", bgcolor: "#ededed" }}>
            นำลิงค์ที่ต้องการจากหน้า Manage Bookcase
            โดยการคลิ๊กไปยังเชลฟ์นั้นๆแล้ว Copy Link{" "}
            <a
              href="https://anyflip.com/center/bookcases/"
              target="_blank"
              rel="noreferrer"
              style={{ color: "#ff4d00" }}
            >
              คลิิ๊กที่นี่เพื่อไปยัง Anyflip
            </a>
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            label="ชื่อ"
            type="text"
            fullWidth
            variant="standard"
            autoComplete="off"
            value={ItemData.item_name}
            onChange={(e) => {
              setItemData({ ...ItemData, item_name: e.target.value });
            }}
          />
          <TextField
            autoFocus
            margin="dense"
            label="Link"
            type="text"
            fullWidth
            variant="standard"
            autoComplete="off"
            value={ItemData.item_value}
            onChange={(e) => {
              setItemData({ ...ItemData, item_value: e.target.value });
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={AddItemHandleClose} sx={{ color: "red" }}>
            ยกเลิก
          </Button>
          <Button
            onClick={() => {
              addItem();
            }}
            variant="contained"
          >
            <SaveAsIcon sx={{ mr: 1 }} />{" "}
            {ItemData.item_id == null ? "เพิ่ม" : "แก้ไข"}ข้อมูล
          </Button>
        </DialogActions>
      </Dialog>
      <Callback
        {...CallbackData}
        close={() => {
          setCallbackData({ ...CallbackData, open: false });
        }}
      />
    </>
  );
}

export default PdfEdit;
