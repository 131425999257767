import React, { useEffect } from "react";
import { Button, Paper } from "@mui/material";
import { useSelector } from "react-redux";
import authAndAccess from "../../utils/authAndAccess";
import Breadcrumb from "../template/Breadcrumb";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import FolderIcon from "@mui/icons-material/Folder";
import ListItemText from "@mui/material/ListItemText";
import Avatar from "@mui/material/Avatar";

export default function Home() {
  const appReducer = useSelector(({ appReducer }) => appReducer);
  const loginReducer = useSelector(({ loginReducer }) => loginReducer);

  useEffect(() => {
    authAndAccess();
    document.title = "Home";
  }, []);

  return (
    <div>
      <Breadcrumb />
      <Paper elevation={5} sx={{ padding: 2 }}>
        <h1>HOME</h1>
        <h2>Feature List</h2>
        <List>
          {[
            "แดชบอร์ด",
            "จำนวนโฟลเดอร์ Image",
            "จำนวน Image ทั้งหมด",
            "จำนวนโฟลเดอร์ PDF",
            "จำนวน PDF ทั้งหมด",
            "จำนวนโฟลเดอร์ YOUTUBE",
            "จำนวน YOUTUBE ทั้งหมด",
            "จำนวน หมวดหมู่สินค้าที่มีการผูก",
            "จำนวน สินค้าที่มีการผูก",
            "จำนวน ผู้ใช้งานระบบ",
          ].map((item) => (
            <ListItem key={item}>
              <ListItemAvatar>
                <Avatar>
                  <FolderIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary={item} secondary={"Cache data"} />
            </ListItem>
          ))}
        </List>
      </Paper>
    </div>
  );
}
