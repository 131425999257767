import React, { useEffect, useState } from "react";
import { API, APP, LOGIN_TOKEN } from "../../Constants";
import { httpClient } from "../../utils/HttpClient";
import { Box, Button, Grid } from "@mui/material";
import TextField from "@mui/material/TextField";
import SaveAsIcon from "@mui/icons-material/SaveAs";

function ImageEditComponent({ item_id, onUploaded }) {
  const [ItemData, setItemData] = useState(null);
  const [ImageFile, setImageFile] = useState(null);
  const [File, setFile] = useState(null);

  // functions
  const loadImageData = async () => {
    const res = await httpClient.get(`${API.URL}/v1/item/${item_id}`);
    if (res.data.status === "success") {
      setItemData(res.data.message);
    }
  };

  const onChangeImage = (e) => {
    const file = e.target.files[0];
    if (file) {
      setFile(file);
      const src = URL.createObjectURL(file);
      setImageFile(src);
    } else {
      setImageFile(null);
    }
  };

  const saveImage = async () => {
    let formData = await new FormData();

    formData.append("item_id", item_id);
    formData.append("fileName", ItemData.item_mime);
    if (File !== null) {
      formData.append("file", File);
    }
    const res = await httpClient.post(
      `${API.URL}/auth/image/update`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: localStorage.getItem(LOGIN_TOKEN),
        },
      }
    );
    if (res) {
      console.log(res);
      onUploaded();
    }
  };

  useEffect(() => {
    loadImageData();
  }, []);

  return (
    ItemData !== null && (
      <>
        <Box sx={{ fontSize: "14px", mb: 1 }}>
          <ul style={{ listStyle: "inside" }}>
            <li>
              ภาพที่อัพโหลดจะถูกสร้างเป็น 2สำเนา{" "}
              <ol>
                <li>
                  สำหรับหน้าเว็บ{" "}
                  <strong style={{ color: "red" }}>1240px พร้อมลายน้ำ</strong>
                </li>
                <li>2.2500px ไม่มีลายน้ำ</li>
              </ol>
            </li>

            <li>ลายน้ำจะถูกประทับลงบนภาพถาวร ไม่สามารถลบได้</li>
            <li>ภาพที่มีขนาดมากกว่า 2,500px จะถูกย่อให้เหลือ 2,500px</li>
          </ul>
        </Box>
        <Grid container spacing={1}>
          <Grid item xs={6}>
            {ImageFile !== null && (
              <Box sx={{ width: "300px", p: 1 }}>
                <img src={ImageFile} alt="" />
              </Box>
            )}
            <Box sx={{ width: ImageFile === null ? "300px" : "50px", p: 1 }}>
              <a
                href={`${API.PUBLIC}${ItemData.item_value}`}
                target="_blank"
                rel="noreferrer"
              >
                <img src={`${API.PUBLIC}${ItemData.item_value}`} alt="" />
              </a>
            </Box>
          </Grid>
          <Grid item xs={6}>
            เลือกภาพใหม่ หากต้องการเปลี่ยน
            <Box sx={{ border: "1px dotted #000", p: 1, borderRadius: 1 }}>
              <input
                id="uploadImage"
                type="file"
                accept="image/*"
                onChange={(e) => {
                  onChangeImage(e);
                }}
              />
              {ImageFile !== null && (
                <Box sx={{ display: "flex", justifyContent: "end" }}>
                  <Button
                    variant="contained"
                    size="small"
                    color="danger"
                    onClick={() => {
                      setImageFile(null);
                      setFile(null);
                      document.querySelector("#uploadImage").value = null;
                    }}
                  >
                    Clear
                  </Button>
                </Box>
              )}
            </Box>
            <TextField
              fullWidth
              id="outlined-basic"
              label="แท็กภาพ (Alt)"
              variant="outlined"
              size="small"
              sx={{ mt: 2 }}
              value={ItemData.item_mime}
              onChange={(e) => {
                setItemData({ ...ItemData, item_mime: e.target.value });
              }}
            />
            <Box sx={{ mt: 2 }}>
              <strong>ชื่อไฟล์ :</strong> {ItemData.item_name}
            </Box>
            <Box sx={{ mt: 2 }}>
              <strong>สร้างเมื่อ :</strong> {ItemData.item_create_at}
            </Box>
            <Box sx={{ mt: 2 }}>
              <strong>อัพเดทเมื่อ :</strong> {ItemData.item_update_at}
            </Box>
            <Box sx={{ mt: 2, display: "flex", justifyContent: "end" }}>
              <Button
                variant="contained"
                size="small"
                onClick={() => {
                  saveImage();
                }}
              >
                <SaveAsIcon sx={{ mr: 1 }} /> บันทึกข้อมูล
              </Button>
            </Box>
          </Grid>
        </Grid>
      </>
    )
  );
}

export default ImageEditComponent;
