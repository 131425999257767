import React, { useEffect, useState } from "react";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarExport,
  GridToolbarDensitySelector,
} from "@mui/x-data-grid";
import LinearProgress from "@mui/material/LinearProgress";
import { Box, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import { httpClient } from "../../utils/HttpClient";
import { API, LOGIN_TOKEN } from "../../Constants";

const columns = [
  { field: "folder_id", headerName: "ID", width: 70, hide: true },
  {
    field: "folder_name",
    headerName: "ชื่อเรียก(ภายใน)",
    width: 300,
    renderCell: (row) => (
      <Typography
        variant="body1"
        component={Link}
        to={`/folders/id/${row.row.folder_id}`}
      >
        {row.row.folder_name}
      </Typography>
    ),
  },
  { field: "folder_title", headerName: "ชื่อที่ปรากฏหน้าเว็บ", width: 300 },
  { field: "folder_count", headerName: "items", width: 70, align: "center" },
  {
    field: "folder_create_at",
    headerName: "สร้างเมื่อ",
    width: 180,
    align: "center",
  },
  {
    field: "folder_update_at",
    headerName: "อัพเดทเมื่อ",
    width: 180,
    align: "center",
  },
];

export default function DataGridTest() {
  const [TableData, setTableData] = useState({ data: [], total: 0 });
  const [DataGridProps, setDataGridProps] = useState({
    columns: columns,
    page: 1,
    pageSize: 10,
    rowsPerPageOptions: [5, 10, 20, 50, 100],
    loading: false,
    checkboxSelection: false,
    pagination: true,
  });
  const [DataSearch, setDataSearch] = useState({
    where: "",
    order: "",
  });

  const fillterModelChange = (model) => {
    const column = model.items[0].columnField;
    const operator = model.items[0].operatorValue;
    const value = model.items[0].value;
    let where_text = "";
    let required_value = true;
    switch (operator) {
      case "contains":
        where_text = `${column} LIKE '%${value}%'`;
        required_value = true;
        break;
      case "equals":
        where_text = `${column}='${value}'`;
        required_value = true;
        break;
      case "startsWith":
        where_text = `${column} LIKE '${value}%'`;
        required_value = true;
        break;
      case "endsWith":
        where_text = `${column} LIKE '%${value}'`;
        required_value = true;
        break;
      case "isEmpty":
        where_text = `${column} IS NULL`;
        required_value = false;
        break;
      case "isNotEmpty":
        where_text = `${column} IS NOT NULL`;
        required_value = false;
        break;
      default:
        where_text = "";
        break;
    }

    if (required_value && value !== undefined) {
      setDataSearch({ ...DataSearch, where: where_text });
    }
  };

  const tableSearchUrl = () => {
    const type = "image";
    const params = {
      page: DataGridProps.page,
      type: type,
      perpage: DataGridProps.pageSize,
      search: DataSearch.where,
      order: DataSearch.order,
    };
    const fullUrl = `${API.URL}/auth/${type}/folders?${new URLSearchParams(
      params
    ).toString()}`;
    return fullUrl;
  };

  const loadFolderList = async () => {
    const fullUrl = tableSearchUrl();
    let result = await httpClient.get(`${fullUrl}`, {
      headers: {
        Authorization: localStorage.getItem(LOGIN_TOKEN),
      },
    });

    if (result.data.status === "success") {
      setTableData(result.data.message);
    } else {
      console.warn(result.data);
    }
  };

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <GridToolbarDensitySelector />
        <GridToolbarExport />
      </GridToolbarContainer>
    );
  }

  useEffect(() => {
    if (TableData.data.length < 1) {
      loadFolderList();
    }
    return () => {};
  }, []);

  useEffect(() => {
    loadFolderList();
    return () => {};
  }, [DataGridProps, DataSearch]);

  return (
    <>
      <Box>
        <div style={{ height: 550, width: "100%" }}>
          <DataGrid
            {...DataGridProps}
            rows={TableData.data}
            rowCount={TableData.total}
            getRowId={(row) => row.folder_id}
            components={{
              Toolbar: CustomToolbar,
              LoadingOverlay: LinearProgress,
            }}
            paginationMode="server"
            onPageChange={(page) => {
              //console.log("Current Page: ", page);
              setDataGridProps({ ...DataGridProps, page: page });
            }}
            onFilterModelChange={(model) => {
              //console.log("Fillter", model);
              fillterModelChange(model);
            }}
            onPageSizeChange={(pageSize) => {
              //console.log("page size change", pageSize);
              setDataGridProps({ ...DataGridProps, pageSize: pageSize });
            }}
            onSortModelChange={(model) => {
              //console.log("Sort", model);
              const column = model[0].field;
              const sort = model[0].sort;
              const order = `${column} ${sort}`;
              setDataSearch({ ...DataSearch, order: order });
            }}
            sx={{
              ".MuiDataGrid-columnHeaders": {
                backgroundColor: "#eeeff6",
                ".MuiDataGrid-columnHeaderTitle": {
                  fontWeight: "bold",
                },
              },
            }}
          />
        </div>
      </Box>
    </>
  );
}
