import React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { useTheme } from "@mui/styles";

// Icon ----
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import ReportGmailerrorredIcon from "@mui/icons-material/ReportGmailerrorred";
//

function ConfirmDialog({
  open = false,
  handleClose,
  data = { type: "delete|confirm", title: "", message: "" },
  returnData,
  confirmed,
}) {
  const theme = useTheme();
  return (
    <div>
      <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
        {data.type === "confirm" && (
          <DialogTitle
            sx={{
              bgcolor: theme.palette.light.main,
              color: theme.palette.text.theme,
            }}
          >
            <ReportGmailerrorredIcon sx={{ fontSize: "2rem", mr: 2 }} />
            {data.title}
          </DialogTitle>
        )}
        {data.type === "delete" && (
          <DialogTitle
            sx={{
              bgcolor: theme.palette.danger.main,
              color: theme.palette.text.light,
            }}
          >
            <DeleteForeverIcon sx={{ fontSize: "2rem", mr: 2 }} />
            {data.title}
          </DialogTitle>
        )}
        <DialogContent>
          <DialogContentText
            sx={{ mt: 2 }}
            textAlign="center"
            dangerouslySetInnerHTML={{ __html: data.message }}
          ></DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            color={data.type === "delete" ? "primary" : "danger"}
            onClick={handleClose}
          >
            ยกเลิก
          </Button>
          <Button
            variant="contained"
            color={data.type === "delete" ? "danger" : "theme"}
            onClick={() => {
              confirmed(true);
            }}
            autoFocus
          >
            ยืนยัน!
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default ConfirmDialog;
