import { Box, Button, Grid, Paper, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import AddFolderToCategory from "../disalog/AddFolderToCategory";
// icon
import PhotoSizeSelectActualIcon from "@mui/icons-material/PhotoSizeSelectActual";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import YouTubeIcon from "@mui/icons-material/YouTube";
import OpenInBrowserIcon from "@mui/icons-material/OpenInBrowser";
import FolderIcon from "@mui/icons-material/Folder";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
//
//List
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
//
import { httpClient } from "../../utils/HttpClient";
import { API, LOGIN_TOKEN } from "../../Constants";
import Badge from "@mui/material/Badge";

const PageDataInit = {
  virtuemart_category_id: "",
  category_name: "",
  category_name_short: "",
  slug: "",
  folders: {
    pdf: [],
    image: [],
    vdo: [],
  },
  ordering: "",
  published: "",
  file_title: "",
  file_mimetype: "",
  file_url: "",
};

const AddFolderDialogInit = {
  open: false,
  type: "",
  target_id: null,
  AddedData: [],
};

function Category_Media() {
  let { id } = useParams();
  const navigate = useNavigate();
  const [PageData, setPageData] = useState(PageDataInit);
  const [AddFolderDialog, setAddFolderDialog] = useState(AddFolderDialogInit);

  const loadCategoryData = async () => {
    const result = await httpClient.get(`${API.URL}/v1/categoryData/${id}`, {
      headers: {
        Authorization: localStorage.getItem(LOGIN_TOKEN),
      },
    });
    setPageData(result.data.message);
    console.log(result.data);
  };

  useEffect(() => {
    loadCategoryData();
    document.title = "Categories Media";
  }, []);

  return (
    <>
      <Box sx={{ mb: 2 }}>
        <Button
          variant="contained"
          color="info"
          onClick={() => {
            navigate(-1);
          }}
        >
          BACK
        </Button>
      </Box>
      <Paper elevation={1} sx={{ p: 1 }}>
        <Grid container spacing={2}>
          <Grid item xs={3}>
            <img
              src={`https://admin.thaippe.com/${PageData.file_url}`}
              alt=""
            />
          </Grid>
          <Grid item xs={9}>
            <Typography variant="h6" component="div">
              {PageData.category_name}
            </Typography>
            <table style={{ color: "#000" }}>
              <tbody>
                <tr>
                  <td align="right" style={{ paddingRight: "10px" }}>
                    View on web
                  </td>
                  <td>
                    :
                    <Button
                      component="a"
                      href={`http://app.thaippe.com/catalog/home/${id}`}
                      target="_blank"
                      rel=""
                      color="info"
                    >
                      <OpenInBrowserIcon /> ดูบนเว็บไซต์
                    </Button>
                  </td>
                </tr>
                <tr>
                  <td align="right" style={{ paddingRight: "10px" }}>
                    Short Slug
                  </td>
                  <td>: {PageData.category_name_short}</td>
                </tr>
                <tr>
                  <td align="right" style={{ paddingRight: "10px" }}>
                    ID
                  </td>
                  <td>: {PageData.virtuemart_category_id}</td>
                </tr>
              </tbody>
            </table>
          </Grid>
        </Grid>
      </Paper>

      <Grid container spacing={2}>
        <Grid item xs={4}>
          <Paper elevation={1} sx={{ p: 1, mt: 2 }}>
            <Typography variant="h6" component="div">
              <PhotoSizeSelectActualIcon sx={{ mr: 1 }} />
              <Badge
                badgeContent={PageData.folders.image.length}
                color="danger"
                showZero
              >
                IMAGE Folders
              </Badge>
              <Button
                variant="contained"
                color="secondary"
                sx={{ float: "right" }}
                size="small"
                onClick={() => {
                  setAddFolderDialog({
                    ...AddFolderDialog,
                    open: true,
                    type: "image",
                    target_id: id,
                    AddedData: PageData.folders.image,
                  });
                }}
              >
                <AddCircleOutlineIcon sx={{ mr: 1 }} />
                เพิ่ม Image
              </Button>
            </Typography>
            <Box>
              <List>
                {PageData.folders.image.map((item) => (
                  <ListItem key={item.folder_id}>
                    <ListItemIcon sx={{ minWidth: "fit-content", pr: 1 }}>
                      <FolderIcon />
                    </ListItemIcon>
                    <ListItemText
                      primary={item.folder_name}
                      secondary={item.folder_count + " รายการ"}
                    />
                  </ListItem>
                ))}
              </List>
            </Box>
          </Paper>
        </Grid>
        <Grid item xs={4}>
          <Paper elevation={1} sx={{ p: 1, mt: 2 }}>
            <Typography variant="h6" component="div">
              <PictureAsPdfIcon sx={{ mr: 1 }} />
              <Badge
                badgeContent={PageData.folders.pdf.length}
                color="danger"
                showZero
              >
                PDF Folders
              </Badge>
              <Button
                variant="contained"
                color="info"
                sx={{ float: "right" }}
                size="small"
                onClick={() => {
                  setAddFolderDialog({
                    ...AddFolderDialog,
                    open: true,
                    type: "pdf",
                    target_id: id,
                    AddedData: PageData.folders.pdf,
                  });
                }}
              >
                <AddCircleOutlineIcon sx={{ mr: 1 }} />
                เพิ่ม pdf
              </Button>
            </Typography>
            <Box>
              <List>
                {PageData.folders.pdf.map((item) => (
                  <ListItem key={item.folder_id}>
                    <ListItemIcon sx={{ minWidth: "fit-content", pr: 1 }}>
                      <FolderIcon />
                    </ListItemIcon>
                    <ListItemText
                      primary={item.folder_name}
                      secondary={item.folder_count + " รายการ"}
                    />
                  </ListItem>
                ))}
              </List>
            </Box>
          </Paper>
        </Grid>
        <Grid item xs={4}>
          <Paper elevation={1} sx={{ p: 1, mt: 2 }}>
            <Typography variant="h6" component="div">
              <YouTubeIcon sx={{ mr: 1 }} />
              <Badge
                badgeContent={PageData.folders.vdo.length}
                color="danger"
                showZero
              >
                VDO Folders
              </Badge>
              <Button
                variant="contained"
                color="danger"
                sx={{ float: "right" }}
                size="small"
                onClick={() => {
                  setAddFolderDialog({
                    ...AddFolderDialog,
                    open: true,
                    type: "vdo",
                    target_id: id,
                    AddedData: PageData.folders.vdo,
                  });
                }}
              >
                <AddCircleOutlineIcon sx={{ mr: 1 }} />
                เพิ่ม vdo
              </Button>
            </Typography>
            <Box>
              <List>
                {PageData.folders.vdo.map((item) => (
                  <ListItem key={item.folder_id}>
                    <ListItemIcon sx={{ minWidth: "fit-content", pr: 1 }}>
                      <FolderIcon />
                    </ListItemIcon>
                    <ListItemText
                      primary={item.folder_name}
                      secondary={item.folder_count + " รายการ"}
                    />
                  </ListItem>
                ))}
              </List>
            </Box>
          </Paper>
        </Grid>
      </Grid>
      <AddFolderToCategory
        {...AddFolderDialog}
        handleClose={() => {
          setAddFolderDialog(AddFolderDialogInit);
          console.log("OK");
        }}
        parentReloadData={() => {
          loadCategoryData();
        }}
      />
    </>
  );
}

export default Category_Media;
