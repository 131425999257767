import * as React from "react";
import { styled, useTheme } from "@mui/material/styles";

import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import { Link, useNavigate } from "react-router-dom";

// Icon
import IconButton from "@mui/material/IconButton";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import GroupIcon from "@mui/icons-material/Group";
import YouTubeIcon from "@mui/icons-material/YouTube";
import PhotoIcon from "@mui/icons-material/Photo";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import AccountTreeIcon from "@mui/icons-material/AccountTree";
import DisplaySettingsIcon from "@mui/icons-material/DisplaySettings";
//
import {
  Box,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import HomeIcon from "@mui/icons-material/Home";
import LoginIcon from "@mui/icons-material/Login";
import { useDispatch } from "react-redux";
import * as loginActions from "./../../actions/login.actions";
import * as appActions from "./../../actions/app.actions";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import Collapse from "@mui/material/Collapse";

const drawerWidth = 240;
const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: "flex-end",
}));

export default function DrawerMenu(props) {
  const appReducer = useSelector(({ appReducer }) => appReducer);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const theme = useTheme();
  const location = useLocation();

  const MainSection = ({ title = "", ListIcon }) => (
    <ListItem>
      <ListItemIcon sx={{ minWidth: 40 }}>
        <ListIcon sx={{ color: theme.palette.primary.main }} />
      </ListItemIcon>
      <ListItemText
        primaryTypographyProps={{
          color: theme.palette.primary.main,
          fontWeight: "bold",
        }}
        primary={title}
      />
    </ListItem>
  );

  let CustomListItem = ({
    to,
    primary,
    icon,
    primaryTypographyProps = { color: "#2f2f2f" },
  }) => (
    <ListItem
      button
      component={Link}
      to={to}
      selected={location.pathname.search(to) > -1}
      sx={{ "&.Mui-selected": { bgcolor: "#9cb7ca" } }}
    >
      <ListItemIcon sx={{ minWidth: 40 }}>{icon}</ListItemIcon>
      <ListItemText
        primary={primary}
        primaryTypographyProps={primaryTypographyProps}
      />
    </ListItem>
  );

  return (
    <div>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: drawerWidth,
            boxSizing: "border-box",
            bgcolor: "#f0f0f0",
          },
        }}
        variant="persistent"
        anchor="left"
        open={appReducer.DrawerOpen}
      >
        <DrawerHeader>
          <IconButton
            onClick={() => {
              dispatch(appActions.drawerClose());
            }}
          >
            {theme.direction === "ltr" ? (
              <ChevronLeftIcon />
            ) : (
              <ChevronRightIcon />
            )}
          </IconButton>
        </DrawerHeader>
        <Box sx={{ alignSelf: "center" }}>
          <img
            src={`${process.env.PUBLIC_URL}/images/logo.png`}
            style={{ width: "120px", padding: "10px" }}
            alt="logo"
          />
        </Box>
        <Divider />
        <List>
          <CustomListItem to="/home" primary="HOME" icon={<HomeIcon />} />

          <CustomListItem
            to="/image/folders"
            primary="จัดการสื่อ IMAGE"
            icon={<PhotoIcon />}
          />

          <CustomListItem
            to="/pdf/folders"
            primary="จัดการสื่อ PDF"
            icon={<PictureAsPdfIcon />}
          />

          <CustomListItem
            to="/vdo/folders"
            primary="จัดการสื่อ VDO"
            icon={<YouTubeIcon />}
          />

          <MainSection title="หมวดหมู่สินค้า" ListIcon={AccountTreeIcon} />
          <CustomListItem to="/category" primary="ผูกโฟลเดอร์มีเดียร์" />
          <MainSection title="อื่นๆ" ListIcon={DisplaySettingsIcon} />
          <CustomListItem to="/users" primary="จัดการผู้ใช้งาน" />
        </List>
        <Divider />
        <List>
          <ListItem
            key="logout"
            button
            sx={{ bgcolor: "#c6282829" }}
            onClick={() => {
              dispatch(loginActions.logout(navigate));
            }}
          >
            <ListItemIcon>
              <LoginIcon sx={{ color: theme.palette.danger.main }} />
            </ListItemIcon>
            <ListItemText
              primary="LOG OUT"
              primaryTypographyProps={{
                color: theme.palette.danger.main,
              }}
            />
          </ListItem>
        </List>
      </Drawer>
    </div>
  );
}
