import * as React from "react";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { Grid, TextField } from "@mui/material";
import * as yup from "yup";
import { useFormik } from "formik";
import * as loginActions from "./../../actions/login.actions";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import DialogError from "../disalog/DialogError";
import { LOGIN_STATUS } from "../../Constants";

export default function Login() {
  const dispath = useDispatch();
  const navigate = useNavigate();
  const loginReducer = useSelector(({ loginReducer }) => loginReducer);
  const validationSchema = yup.object({
    u_username: yup
      .string("Enter your username")
      .required("username is required"),
    u_password: yup
      .string("Enter your password")
      .min(4, "Password should be of minimum 4 characters length")
      .required("Password is required"),
  });

  const [dialogData, setdialogData] = React.useState({
    show: false,
    type: "success",
    title: null,
    message: null,
  });

  const closeDialog = () => {
    dispath(loginActions.logout(navigate));
  };

  const formik = useFormik({
    initialValues: {
      u_username: "",
      u_password: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      dispath(
        loginActions.login(values.u_username, values.u_password, navigate)
      );
    },
  });

  React.useEffect(() => {
    document.title = "LOGIN - Gallery's ThaiPPE";
  }, []);

  return (
    <Grid
      container
      direction="row"
      justifyContent="center"
      alignItems="center"
      style={{ bacgroundColor: "#f1f1f1" }}
    >
      <div>
        <form onSubmit={formik.handleSubmit}>
          <Card sx={{ width: 345 }} component="div">
            <CardMedia
              component="img"
              height="180"
              image={`${process.env.PUBLIC_URL}/images/logo.png`}
              alt="logo"
              sx={{ p: 4 }}
            />
            <CardContent>
              <Typography gutterBottom variant="h5" component="span">
                Login Panel
              </Typography>

              <TextField
                style={{ marginTop: 20 }}
                fullWidth
                size="small"
                label="Username *"
                variant="outlined"
                id="u_username"
                name="u_username"
                value={formik.values.u_username}
                onChange={formik.handleChange}
                error={
                  formik.touched.u_username && Boolean(formik.errors.u_username)
                }
                helperText={
                  formik.touched.u_username && formik.errors.u_username
                }
              />

              <TextField
                style={{ marginTop: 20 }}
                fullWidth
                type="password"
                autoComplete="on"
                size="small"
                label="Password *"
                variant="outlined"
                id="u_password"
                name="u_password"
                value={formik.values.u_password}
                onChange={formik.handleChange}
                error={
                  formik.touched.u_password && Boolean(formik.errors.u_password)
                }
                helperText={
                  formik.touched.u_password && formik.errors.u_password
                }
              />
            </CardContent>
            <CardActions>
              <Button
                type="submit"
                fullWidth
                size="small"
                variant="contained"
                color="primary"
              >
                Login Now
              </Button>
            </CardActions>
          </Card>
        </form>
      </div>
      <DialogError
        show={loginReducer.error}
        type="error"
        title="Error"
        message={loginReducer.result}
        close={closeDialog}
      />
    </Grid>
  );
}
