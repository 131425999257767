import { Box, Button, Paper, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import CustomizedBreadcrumbs from "../template/Breadcrumb";
// icon
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import { httpClient } from "../../utils/HttpClient";
import { API, APP, LOGIN_TOKEN } from "../../Constants";
import { Link, useParams } from "react-router-dom";
import SaveAsIcon from "@mui/icons-material/SaveAs";
import FolderOpenIcon from "@mui/icons-material/FolderOpen";
import PhotoSizeSelectActualIcon from "@mui/icons-material/PhotoSizeSelectActual";
import YouTubeIcon from "@mui/icons-material/YouTube";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarExport,
  GridToolbarDensitySelector,
} from "@mui/x-data-grid";
import LinearProgress from "@mui/material/LinearProgress";
//

import TextField from "@mui/material/TextField";
// Dialog
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Callback from "../snackbar/Callback";
import ConfirmDialog from "../disalog/Confirm";
//

const typeAssets = {
  image: {
    title: "Image (รูปภาพ)",
    icon: <PhotoSizeSelectActualIcon fontSize="large" />,
    color: "#c58539",
  },
  pdf: {
    title: "PDF (Anyflip)",
    icon: <PictureAsPdfIcon fontSize="large" />,
    color: "#0e6b74",
  },
  vdo: {
    title: "VDO (Toutube)",
    icon: <YouTubeIcon fontSize="large" />,
    color: "#cd3030",
  },
};

function FolderList() {
  const { type } = useParams();
  const [PageType, setPageType] = useState(type);
  const pageName = typeAssets[type].title;
  const breadCrumb = [{ path: type + "/folders", name: pageName }];
  const columns = [
    { field: "folder_id", headerName: "ID", width: 70, hide: true },
    {
      field: "folder_name",
      headerName: "ชื่อเรียก(ภายใน)",
      width: 300,
      renderCell: (row) => (
        <Typography
          variant="body1"
          component={Link}
          to={`/${row.row.folder_type}/folders/id/${row.row.folder_id}`}
        >
          {row.row.folder_name}
        </Typography>
      ),
    },
    { field: "folder_title", headerName: "ชื่อที่ปรากฏหน้าเว็บ", width: 300 },
    { field: "folder_count", headerName: "items", width: 70, align: "center" },
    {
      field: "folder_create_at",
      headerName: "สร้างเมื่อ",
      width: 180,
      align: "center",
    },
    {
      field: "folder_update_at",
      headerName: "อัพเดทเมื่อ",
      width: 180,
      align: "center",
    },
  ];
  /*  */
  const [TableData, setTableData] = useState({ data: [], total: 0 });
  const [DataGridProps, setDataGridProps] = useState({
    columns: columns,
    page: 1,
    pageSize: 30,
    rowsPerPageOptions: [10, 30, 50, 100], // จำนวนแถวต่อหน้า max 100 สำหรับเวอร์ชั่นฟรี
    loading: false,
    checkboxSelection: false,
    pagination: true,
  });
  const InitDataSearch = {
    where: "",
    order: "",
  };
  const [DataSearch, setDataSearch] = useState(InitDataSearch);

  const fillterModelChange = (model) => {
    const column = model.items[0].columnField;
    const operator = model.items[0].operatorValue;
    const value = model.items[0].value;
    let where_text = "";
    let required_value = true;
    switch (operator) {
      case "contains":
        where_text = `${column} LIKE '%${value}%'`;
        required_value = true;
        break;
      case "equals":
        where_text = `${column}='${value}'`;
        required_value = true;
        break;
      case "startsWith":
        where_text = `${column} LIKE '${value}%'`;
        required_value = true;
        break;
      case "endsWith":
        where_text = `${column} LIKE '%${value}'`;
        required_value = true;
        break;
      case "isEmpty":
        where_text = `${column} IS NULL`;
        required_value = false;
        break;
      case "isNotEmpty":
        where_text = `${column} IS NOT NULL`;
        required_value = false;
        break;
      default:
        where_text = "";
        break;
    }

    if (required_value && value !== undefined) {
      setDataSearch({ ...DataSearch, where: where_text });
    }
  };

  const tableSearchUrl = () => {
    const params = {
      page: DataGridProps.page,
      type: type,
      perpage: DataGridProps.pageSize,
      search: DataSearch.where,
      order: DataSearch.order,
    };
    const fullUrl = `${API.URL}/auth/${type}/folders?${new URLSearchParams(
      params
    ).toString()}`;
    return fullUrl;
  };

  const loadFolderList = async () => {
    const fullUrl = tableSearchUrl();
    let result = await httpClient.get(`${fullUrl}`, {
      headers: {
        Authorization: localStorage.getItem(LOGIN_TOKEN),
      },
    });

    if (result.data.status === "success") {
      setTableData(result.data.message);
    } else {
      console.warn(result.data);
    }
  };

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <GridToolbarDensitySelector />
        <GridToolbarExport />
      </GridToolbarContainer>
    );
  }
  /*  */

  const FolderDataInit = {
    folder_type: type,
    folder_name: null,
    folder_title: null,
  };

  const [FolderData, setFolderData] = useState(FolderDataInit);
  // Paginate

  const [AddItemOpen, setAddItemOpen] = React.useState(false);

  const AddItemHandleClickOpen = () => {
    setAddItemOpen(true);
  };

  const AddItemHandleClose = () => {
    setAddItemOpen(false);
  };
  const [CallbackData, setCallbackData] = useState({
    open: false,
    type: "success",
    message: "init",
    duration: 2000,
  });

  const addFolder = async () => {
    const res = await httpClient.put(
      `${API.URL}/auth/folder/put`,
      {
        folder_name: FolderData.folder_name,
        folder_title: FolderData.folder_title,
        folder_type: PageType,
      },
      {
        headers: {
          Authorization: localStorage.getItem(LOGIN_TOKEN),
        },
      }
    );
    if (res.data.status === "success") {
      AddItemHandleClose();
      loadFolderList();
      setCallbackData({
        ...CallbackData,
        open: true,
        type: "success",
        message: res.data.message,
      });
    } else {
      setCallbackData({
        ...CallbackData,
        open: true,
        type: "error",
        message: "ผิดพลาด...กรุณาแจ้งผู้ดูแลระบบ",
      });
      console.log(res.data);
    }
  };

  const dataTableUpdate = async () => {
    console.log("Load New Data");
  };

  useEffect(() => {
    document.title = pageName;
  }, []);

  useEffect(() => {
    setPageType(type);
    setDataSearch(InitDataSearch);
    setTableData({ data: [], total: 0 });
    setTimeout(() => {
      loadFolderList();
    }, 500);
  }, [type]);

  useEffect(() => {
    loadFolderList();
  }, [PageType]);

  useEffect(() => {
    loadFolderList();
  }, [DataGridProps, DataSearch]);

  return (
    <>
      <CustomizedBreadcrumbs links={breadCrumb} />

      <Typography
        variant="h5"
        component="h4"
        sx={{ mb: 2, color: typeAssets[type].color, fontWeight: "bold" }}
      >
        โฟลเดอร์ {typeAssets[type].icon} {pageName} ทั้งหมด{" "}
        <Button
          variant="contained"
          color="info"
          onClick={() => {
            AddItemHandleClickOpen();
          }}
        >
          <AddCircleOutlineIcon sx={{ mr: 1 }} />
          เพิ่มโฟลเดอร์ {PageType}
        </Button>
      </Typography>
      <Box>
        <div style={{ height: 1000, width: "100%" }}>
          <DataGrid
            {...DataGridProps}
            rows={TableData.data}
            rowCount={TableData.total}
            getRowId={(row) => row.folder_id}
            components={{
              Toolbar: CustomToolbar,
              LoadingOverlay: LinearProgress,
            }}
            paginationMode="server"
            onPageChange={(page) => {
              //console.log("Current Page: ", page);
              setDataGridProps({ ...DataGridProps, page: page });
            }}
            onFilterModelChange={(model) => {
              //console.log("Fillter", model);
              fillterModelChange(model);
            }}
            onPageSizeChange={(pageSize) => {
              //console.log("page size change", pageSize);
              setDataGridProps({ ...DataGridProps, pageSize: pageSize });
            }}
            onSortModelChange={(model) => {
              //console.log("Sort", model);
              const column = model[0].field;
              const sort = model[0].sort;
              const order = `${column} ${sort}`;
              setDataSearch({ ...DataSearch, order: order });
            }}
            sx={{
              ".MuiDataGrid-columnHeaders": {
                backgroundColor: "#eeeff6",
                ".MuiDataGrid-columnHeaderTitle": {
                  fontWeight: "bold",
                },
              },
            }}
          />
          {/* <DataGrid
            {...DataGridProps}
            components={{
              Toolbar: CustomToolbar,
              LoadingOverlay: LinearProgress,
            }}
            getRowId={(row) => row.folder_id}
            rows={ItemList.data}
            rowCount={ItemList.total}
            paginationMode="server"
            onPageChange={(page) => {
              console.log("Current Page: ", page);
              setReqParams({ ...ReqParams, page: page + 1 });
            }}
            onFilterModelChange={(model) => {
              console.log("Fillter", model);
            }}
            onPageSizeChange={(pageSize) => {
              console.log("page size change", pageSize);
              setTablePerPage(pageSize);
            }}
            sx={{
              ".MuiDataGrid-columnHeaders": {
                backgroundColor: "#eeeff6",
                ".MuiDataGrid-columnHeaderTitle": {
                  fontWeight: "bold",
                },
              },
            }}
          /> */}
        </div>
      </Box>

      <Dialog open={AddItemOpen} onClose={AddItemHandleClose}>
        <DialogTitle>
          {typeAssets[type].icon} เพิ่ม Folder {typeAssets[type].title}
        </DialogTitle>
        <DialogContent>
          {/* <DialogContentText>
            นำลิงค์ที่ต้องการจากหน้า Manage Bookcase
            โดยการคลิ๊กไปยังเชลฟ์นั้นๆแล้ว Copy Link
          </DialogContentText> */}
          <TextField
            autoFocus
            margin="dense"
            label="ชื่อเรียก"
            type="text"
            fullWidth
            variant="standard"
            autoComplete="off"
            value={FolderData.item_name}
            onChange={(e) => {
              setFolderData({ ...FolderData, folder_name: e.target.value });
            }}
          />
          <TextField
            autoFocus
            margin="dense"
            label="ชื่อที่ปรากฏหน้าเว็บ"
            type="text"
            fullWidth
            variant="standard"
            autoComplete="off"
            value={FolderData.item_title}
            onChange={(e) => {
              setFolderData({ ...FolderData, folder_title: e.target.value });
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={AddItemHandleClose} sx={{ color: "red" }}>
            ยกเลิก
          </Button>
          <Button
            onClick={() => {
              addFolder();
            }}
            variant="contained"
          >
            <SaveAsIcon sx={{ mr: 1 }} /> เพิ่มโฟลเดอร์
          </Button>
        </DialogActions>
      </Dialog>

      <Callback
        {...CallbackData}
        close={() => {
          setCallbackData({ ...CallbackData, open: false });
        }}
      />
    </>
  );
}

export default FolderList;
