import { Box, Button, Paper, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import CustomizedBreadcrumbs from "../../template/Breadcrumb";
// icon
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import { httpClient } from "../../../utils/HttpClient";
import { API, APP, LOGIN_TOKEN } from "../../../Constants";
import { Link } from "react-router-dom";
import SaveAsIcon from "@mui/icons-material/SaveAs";
import FolderOpenIcon from "@mui/icons-material/FolderOpen";
//
// Table
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Pagination from "@mui/material/Pagination";
//
import TextField from "@mui/material/TextField";
// Dialog
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Callback from "../../snackbar/Callback";
import ConfirmDialog from "../../disalog/Confirm";
//

const pageName = "PDF (ANYFLIP)";
const breadCrumb = [{ path: "pdf", name: pageName }];
const FolderDataInit = {
  folder_type: "pdf",
  folder_name: null,
  folder_title: null,
};

function PdfManage() {
  const [ItemList, setItemList] = useState({
    data: [],
    total: 0,
    per_page: 0,
  });
  const [FolderData, setFolderData] = useState(FolderDataInit);
  // Paginate
  const [TablePage, setTablePage] = useState(1);

  const [TablePerPage, setTablePerPage] = useState(30);
  const TablePageChange = async (e, val) => {
    setTablePage(val);
    setReqParams({ ...ReqParams, page: val });
  };
  const [ReqParams, setReqParams] = useState({
    type: "pdf",
    page: TablePage,
    perpage: TablePerPage,
  });
  // Using : new URLSearchParams( ReqParams ).toString();
  //

  const [AddItemOpen, setAddItemOpen] = React.useState(false);

  const AddItemHandleClickOpen = () => {
    setAddItemOpen(true);
  };

  const AddItemHandleClose = () => {
    setAddItemOpen(false);
  };
  const [CallbackData, setCallbackData] = useState({
    open: false,
    type: "success",
    message: "TEST CALLBACK",
    duration: 2000,
  });

  const loadFolderList = async () => {
    const result = await httpClient.get(
      `${API.URL}/auth/pdf/folders?${new URLSearchParams(
        ReqParams
      ).toString()}`,
      {
        headers: {
          Authorization: localStorage.getItem(LOGIN_TOKEN),
        },
      }
    );
    console.log(result);
    if (result.data.status === "success") {
      setItemList(result.data.message);
      console.info(result);
    } else {
      console.warn(result.data);
    }
  };

  const addFolder = async () => {
    const res = await httpClient.put(
      `${API.URL}/auth/folder/put`,
      {
        folder_name: FolderData.folder_name,
        folder_title: FolderData.folder_title,
        folder_type: FolderData.folder_type,
      },
      {
        headers: {
          Authorization: localStorage.getItem(LOGIN_TOKEN),
        },
      }
    );
    if (res.data.status === "success") {
      AddItemHandleClose();
      loadFolderList();
      setCallbackData({
        ...CallbackData,
        open: true,
        type: "success",
        message: res.data.message,
      });
    } else {
      setCallbackData({
        ...CallbackData,
        open: true,
        type: "error",
        message: "ผิดพลาด...กรุณาแจ้งผู้ดูแลระบบ",
      });
      console.log(res.data);
    }
  };

  useEffect(() => {
    loadFolderList();
  }, []);

  return (
    <>
      <CustomizedBreadcrumbs links={breadCrumb} />

      <Typography variant="h5" component="h4" sx={{ mb: 2 }}>
        โฟลเดอร์ <PictureAsPdfIcon /> {pageName} ทั้งหมด{" "}
        <Button
          variant="contained"
          color="info"
          onClick={() => {
            AddItemHandleClickOpen();
          }}
        >
          <AddCircleOutlineIcon sx={{ mr: 1 }} />
          เพิ่มโฟลเดอร์
        </Button>
      </Typography>
      <Box>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow sx={{ bgcolor: "#e3e3e3" }}>
              <TableCell>#</TableCell>
              <TableCell align="left">ชื่อเรียก(ภายใน)</TableCell>
              <TableCell align="left">ชื่อที่ปรากฏหน้าเว็บ</TableCell>
              <TableCell align="center">จำนวน items</TableCell>
              <TableCell align="center">สร้างเมื่อ</TableCell>
              <TableCell align="center">อัพเดทเมื่อ</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {ItemList.data.map((row, index) => (
              <TableRow
                key={row.folder_id}
                sx={{
                  "&:last-child td, &:last-child th": { border: 0 },
                  "&: hover": { bgcolor: "#f3f3f3" },
                }}
                component={Link}
                to={`/pdf/manage/${row.folder_id}`}
              >
                <TableCell>{index + 1}</TableCell>
                <TableCell align="left">
                  <FolderOpenIcon sx={{ mr: 1 }} />
                  {row.folder_name}
                </TableCell>
                <TableCell align="left">{row.folder_title}</TableCell>
                <TableCell align="center">{row.folder_count}</TableCell>
                <TableCell align="center">{row.folder_create_at}</TableCell>
                <TableCell align="center">{row.folder_update_at}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <Box sx={{ mt: 4 }}>
          <Pagination
            count={parseInt(Math.ceil(ItemList.total / ItemList.per_page))}
            page={TablePage}
            onChange={TablePageChange}
            showFirstButton
            showLastButton
          />
        </Box>
      </Box>

      <Box sx={{ mt: 4 }}>{JSON.stringify(ItemList)}</Box>

      <Dialog open={AddItemOpen} onClose={AddItemHandleClose}>
        <DialogTitle>เพิ่ม Folder PDF</DialogTitle>
        <DialogContent>
          {/* <DialogContentText>
            นำลิงค์ที่ต้องการจากหน้า Manage Bookcase
            โดยการคลิ๊กไปยังเชลฟ์นั้นๆแล้ว Copy Link
          </DialogContentText> */}
          <TextField
            autoFocus
            margin="dense"
            label="ชื่อเรียก"
            type="text"
            fullWidth
            variant="standard"
            autoComplete="off"
            value={FolderData.item_name}
            onChange={(e) => {
              setFolderData({ ...FolderData, folder_name: e.target.value });
            }}
          />
          <TextField
            autoFocus
            margin="dense"
            label="ชื่อที่ปรากฏหน้าเว็บ"
            type="text"
            fullWidth
            variant="standard"
            autoComplete="off"
            value={FolderData.item_title}
            onChange={(e) => {
              setFolderData({ ...FolderData, folder_title: e.target.value });
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={AddItemHandleClose} sx={{ color: "red" }}>
            ยกเลิก
          </Button>
          <Button
            onClick={() => {
              addFolder();
            }}
            variant="contained"
          >
            <SaveAsIcon sx={{ mr: 1 }} /> เพิ่มโฟลเดอร์
          </Button>
        </DialogActions>
      </Dialog>

      <Callback
        {...CallbackData}
        close={() => {
          setCallbackData({ ...CallbackData, open: false });
        }}
      />
    </>
  );
}

export default PdfManage;
