import { Box, Button, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { API, APP, LOGIN_TOKEN } from "../../Constants";
import { httpClient } from "../../utils/HttpClient";
import Skeleton from "@mui/material/Skeleton";

// Table
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Pagination from "@mui/material/Pagination";
//

// icon
import FolderOpenIcon from "@mui/icons-material/FolderOpen";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import PhotoSizeSelectActualIcon from "@mui/icons-material/PhotoSizeSelectActual";
import AccountTreeIcon from "@mui/icons-material/AccountTree";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";

const CategoriesInit = {
  total: 0,
  per_page: 50,
  data: [],
};

function PdfCategory() {
  let { id } = useParams();
  const navigate = useNavigate();
  const loadCategories = async (params = null) => {
    setIsLoading(true);
    setCategories(CategoriesInit);
    params =
      params !== null ? params : new URLSearchParams(ReqParams).toString();
    const result = await httpClient.get(
      `${API.URL}/v1/category/${id ? id : 0}?${params}`,
      {
        headers: {
          Authorization: localStorage.getItem(LOGIN_TOKEN),
        },
      }
    );

    setCategories(result.data.message);
    setIsLoading(false);
  };
  // Table assets
  const [Categories, setCategories] = useState(CategoriesInit);
  const [IsLoading, setIsLoading] = useState(false);
  const [TablePage, setTablePage] = useState(1);
  const [TablePerPage, setTablePerPage] = useState(50);
  const [ReqParams, setReqParams] = useState({
    page: TablePage,
    perpage: TablePerPage,
  });
  const TablePageChange = async (e, val) => {
    setTablePage(val);
    setReqParams({ ...ReqParams, page: val });
    loadCategories();
  };
  // Using : new URLSearchParams( ReqParams ).toString();
  //

  useEffect(() => {
    loadCategories();
  }, [id, ReqParams]);

  useEffect(() => {
    document.title = "Categories";
  }, []);

  return (
    <>
      <Box>
        <Button
          variant="contained"
          color="info"
          onClick={() => {
            navigate(-1);
          }}
        >
          BACK
        </Button>
      </Box>
      <Box sx={{ mt: 4, mb: 2 }}>
        {Categories.data.length > 0 ? (
          <Pagination
            count={parseInt(Math.ceil(Categories.total / Categories.per_page))}
            page={TablePage}
            onChange={TablePageChange}
            showFirstButton
            showLastButton
          />
        ) : (
          <Skeleton
            variant="rectangular"
            sx={{ width: "50%", height: "40px" }}
          />
        )}
      </Box>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow sx={{ bgcolor: "#e3e3e3" }}>
            <TableCell align="center">
              <PhotoSizeSelectActualIcon />
              <br />
              รูป
            </TableCell>
            <TableCell align="left">
              <AccountTreeIcon sx={{ mr: 1 }} />
              ชื่อหมวดหมู่
            </TableCell>
            <TableCell align="center">
              <FolderOpenIcon />
              <br />
              IMAGE
            </TableCell>
            <TableCell align="center">
              <FolderOpenIcon />
              <br />
              PDF
            </TableCell>
            <TableCell align="center">
              <FolderOpenIcon />
              <br />
              VDO
            </TableCell>
            <TableCell align="center">
              <ModeEditIcon />
              <br />
              เพิ่ม Media
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {Categories.data.length > 0 &&
            Categories.data.map((row, index) => (
              <TableRow
                key={"item_" + row.id}
                sx={{
                  "&:last-child td, &:last-child th": { border: 0 },
                  "&: hover": { bgcolor: "#f3f3f3" },
                  whiteSpace: "normal",
                }}
              >
                <TableCell align="center">
                  <Box sx={{ width: id ? "90px" : "150px", m: "0 auto" }}>
                    <img
                      src={`https://admin.thaippe.com/${row.file_url}`}
                      alt=""
                    />
                  </Box>
                </TableCell>
                <TableCell>
                  <Box sx={{ width: "550px" }}>
                    <Link
                      to={`/category/${row.virtuemart_category_id}`}
                      style={{ fontWeight: "bold", color: "#444" }}
                    >
                      {row.category_name}
                    </Link>
                  </Box>
                </TableCell>
                <TableCell align="center">{row.countImage}</TableCell>
                <TableCell align="center">{row.countPdf}</TableCell>
                <TableCell align="center">{row.countVdo}</TableCell>
                <TableCell align="center">
                  <Button
                    variant="contained"
                    color="primary"
                    component={Link}
                    to={`/category_media/${row.virtuemart_category_id}`}
                  >
                    <AddCircleOutlineIcon sx={{ mr: 1 }} /> เพิ่ม
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          {Categories.data.length <= 0 && (
            <TableRow>
              <TableCell align="center" colSpan={6}>
                <Typography variant="h6" component="div" color="secondary">
                  ไม่มีหมวดหมู่ย่อย
                </Typography>
              </TableCell>
            </TableRow>
          )}
          {IsLoading &&
            [...Array(TablePerPage)].map((item, index) => (
              <TableRow key={`xx_${index}`}>
                <TableCell align="center">
                  <Skeleton
                    variant="rectangular"
                    sx={{ width: "150px", height: "80px", m: "0 auto" }}
                  />
                </TableCell>
                <TableCell>
                  <Skeleton variant="text" sx={{ width: "100%" }} />
                  <Skeleton variant="text" sx={{ width: "100%" }} />
                </TableCell>
                <TableCell>
                  <Skeleton
                    variant="text"
                    sx={{ width: "20px", m: "0 auto" }}
                  />
                </TableCell>
                <TableCell>
                  <Skeleton
                    variant="text"
                    sx={{ width: "20px", m: "0 auto" }}
                  />
                </TableCell>
                <TableCell>
                  <Skeleton
                    variant="text"
                    sx={{ width: "20px", m: "0 auto" }}
                  />
                </TableCell>
                <TableCell>
                  <Skeleton
                    variant="rectangular"
                    sx={{ width: "80px", height: "30px", m: "0 auto" }}
                  />
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>

      {Categories.data.length > 0 && (
        <Box sx={{ mt: 4 }}>
          <Pagination
            count={parseInt(Math.ceil(Categories.total / Categories.per_page))}
            page={TablePage}
            onChange={TablePageChange}
            showFirstButton
            showLastButton
          />
        </Box>
      )}
    </>
  );
}

export default PdfCategory;
