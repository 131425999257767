import React from "react";
import {
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
} from "@mui/material";

import FolderSharedIcon from "@mui/icons-material/FolderShared";

export default function inputFolder({
  label = "",
  type = null,
  value = "",
  handleClick,
  changeIcon = null,
}) {
  return (
    <FormControl fullWidth size="small" variant="outlined" sx={{ mb: 2 }}>
      <InputLabel htmlFor="outlined-adornment">{label}</InputLabel>
      <OutlinedInput
        id="outlined-adornment"
        type={type}
        value={value}
        endAdornment={
          <InputAdornment position="end">
            <IconButton aria-label="show all" onClick={handleClick} edge="end">
              {changeIcon ? changeIcon : <FolderSharedIcon />}
            </IconButton>
          </InputAdornment>
        }
        label={label}
        readOnly
      />
    </FormControl>
  );
}
