import React from "react";
import { useLocation } from "react-router-dom";
import { LOGIN_STATUS } from "../../Constants";

export default function PageNotFound() {
  let location = useLocation();

  console.log(location);

  return (
    <div>
      <h1>404 Page note found.</h1>
      <div>{JSON.stringify(location)}</div>
      <div>{localStorage.getItem(LOGIN_STATUS)}</div>
    </div>
  );
}
