const setinitDefault = {
  allProduct: [],
  allProdId: [],
  allAmount: [],
  totalPrice: 0,
};

const initialState =
  localStorage.getItem("Cart") !== null
    ? JSON.parse(localStorage.getItem("Cart"))
    : setinitDefault;

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = initialState, { type, payload }) => {
  switch (type) {
    case "UPDATE_CART":
      return { ...state, ...payload };
    case "CLEAR_CART":
      return { ...state, ...setinitDefault };
    default:
      return state;
  }
};
