import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import ErrorIcon from "@mui/icons-material/Error";
import WarningIcon from "@mui/icons-material/Warning";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
export default function DialogError({ show, type, title, message, close }) {
  let iconStyle = { fontSize: "3rem" };

  return (
    <div>
      <Dialog
        open={show}
        onClose={close}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" align="center">
          {type === "success" && (
            <CheckCircleIcon style={{ ...iconStyle, color: "#28af96" }} />
          )}
          {type === "warning" && (
            <WarningIcon style={{ ...iconStyle, color: "#ffb886" }} />
          )}
          {type === "error" && (
            <ErrorIcon style={{ ...iconStyle, color: "#dd4c4c" }} />
          )}
          <hr />
          {title}
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            id="alert-dialog-description"
            style={{ minWidth: 300 }}
            align="center"
          >
            {message}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={close} autoFocus variant="outlined" color="primary">
            ปิด
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
