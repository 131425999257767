import { Box, Button, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import { API, LOGIN_TOKEN } from "../../Constants";
import { httpClient } from "../../utils/HttpClient";

import PhotoSizeSelectActualIcon from "@mui/icons-material/PhotoSizeSelectActual";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import PanToolIcon from "@mui/icons-material/PanTool";
import TextField from "@mui/material/TextField";

const thumbsContainer = {
  display: "flex",
  flexDirection: "row",
  flexWrap: "wrap",
  marginTop: 16,
};

const thumb = {
  display: "inline-flex",
  borderRadius: 2,
  border: "1px solid #eaeaea",
  marginBottom: 8,
  marginRight: 8,
  width: 100,
  height: 100,
  padding: 4,
  boxSizing: "border-box",
};

const thumbInner = {
  display: "flex",
  minWidth: 0,
  overflow: "hidden",
};

const img = {
  display: "block",
  width: "auto",
  height: "100%",
  border: "1px dotted #000",
};

const checkFileSize = (file) => {
  if (file.size > 5000000) {
    return {
      code: "file-too-large",
      message: `มีขนาดไฟล์เกิน ${5000000 / 1000000} Mb`,
    };
  }
};

function Previews(props) {
  const [files, setFiles] = useState([]);
  const [FilesAtl, setFilesAtl] = useState([]);
  const [Uploading, setUploading] = useState(false);
  const { getRootProps, getInputProps, fileRejections } = useDropzone({
    maxSize: 5000000,
    accept: {
      "image/*": [],
    },
    validator: checkFileSize,
    onDrop: (acceptedFiles) => {
      setFiles(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        )
      );
    },
  });

  const UpdateFilesAlt = () => {
    const data = files.map((item) => item.name.split(".")[0].replace(" ", ""));
    setFilesAtl(data);
  };

  const uploadForm = async () => {
    setUploading(true);
    let formData = new FormData();
    files.forEach((item, index) => {
      formData.append("file[]", item);
    });
    formData.append("folder_id", props.folder_id);
    formData.append("type", props.type);
    formData.append("alts", JSON.stringify(FilesAtl));

    const res = await httpClient.post(
      `${API.URL}/auth/image/upload`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
          authorization: localStorage.getItem(LOGIN_TOKEN),
        },
      }
    );
    props.onUploaded();
    setUploading(false);
  };

  useEffect(() => {
    // Make sure to revoke the data uris to avoid memory leaks, will run on unmount
    return () => files.forEach((file) => URL.revokeObjectURL(file.preview));
  }, []);

  useEffect(() => {
    UpdateFilesAlt();
  }, [files]);

  const thumbs = files.map((file, index) => (
    <Grid item xs={3} key={file.name}>
      <div>
        <img
          src={file.preview}
          alt=""
          style={img}
          onLoad={() => {
            URL.revokeObjectURL(file.preview);
          }}
        />
      </div>
      <div>
        <TextField
          id={`filesalt_${index}`}
          label="แท็กภาพ (ALT)"
          variant="outlined"
          size="small"
          sx={{ mt: 2 }}
          value={FilesAtl[index] ? FilesAtl[index] : ""}
          onChange={(e) => {
            let alts = Object.assign({}, FilesAtl);
            alts[index] = e.target.value;
            setFilesAtl(alts);
            console.log(alts);
          }}
        />
        <br />
        <small>{(file.size / 1000000).toFixed(1)}Mb</small>
      </div>
    </Grid>
  ));

  return (
    <>
      <section className="container">
        <Box sx={{ fontSize: "14px", mb: 1 }}>
          <ul style={{ listStyle: "inside" }}>
            <li>
              ภาพที่อัพโหลดจะถูกสร้างเป็น 2สำเนา{" "}
              <ul style={{ listStyle: "auto", paddingLeft: "40px" }}>
                <li>
                  สำหรับหน้าเว็บ{" "}
                  <strong style={{ color: "red" }}>1240px พร้อมลายน้ำ</strong>
                </li>
                <li>
                  <strong style={{ color: "red" }}>2000px ไม่มีลายน้ำ</strong>
                </li>
              </ul>
            </li>
            <li>ลายน้ำจะถูกประทับลงบนภาพถาวร ไม่สามารถลบได้</li>
            <li>ภาพที่มีขนาดมากกว่า 2,500px จะถูกย่อให้เหลือ 2,500px</li>
          </ul>
        </Box>
        <Box
          {...getRootProps({ className: "dropzone" })}
          sx={{
            bgcolor: "#28359342",
            p: 2,
            color: "#000",
            textAlign: "center",
            border: "2px dotted #000",
            borderRadius: "10px",
            cursor: "pointer",
          }}
        >
          <input {...getInputProps()} />
          <p>
            <PanToolIcon sx={{ mr: 1 }} />
            ลากภาพมาว่างที่ช่องนี้ หรือ{" "}
            <PhotoSizeSelectActualIcon sx={{ mr: 1 }} />
            คลิ๊กเพื่อเลือกภาพจากคอมพิวเตอร์
          </p>
        </Box>
        {fileRejections.length > 0 && (
          <Box>
            {fileRejections.map((item, index) => (
              <li key={index}>
                {item.file.path} -{" "}
                <span style={{ color: "red" }}>{item.errors[1].message}</span>
              </li>
            ))}
            {JSON.stringify(fileRejections)}
          </Box>
        )}
        <Grid container spacing={1} sx={{ my: 2, minHeight: "50px" }}>
          {thumbs}
        </Grid>
      </section>
      <Box sx={{ textAlign: "right" }}>
        <Button
          variant="contained"
          onClick={() => {
            uploadForm();
          }}
          disabled={files.length < 1 || Uploading === true}
        >
          <CloudUploadIcon sx={{ mr: 1 }} />
          อัพโหลดและบันทึก
        </Button>
      </Box>
    </>
  );
}

export default Previews;
