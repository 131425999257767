export const API = {
  //URL: "http://localhost:8000",
  URL: "https://gallery.thaippe.com/backend",
  LOGIN: "v1/user/login",
  PUBLIC: "https://gallery.thaippe.com/backend/public",
};

export const APP = {
  name: "GALLERY THAIPPE",
  DrawerToggleOpen: "DrawerToggleOpen",
  DrawerToggleClose: "DrawerToggleClose",
};

export const LOGIN_FETCHING = "LOGIN_FETCHING";
export const LOGIN_FAILED = "LOGIN_FAILED";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGOUT = "LOGOUT";
export const LOGIN_STATUS = "LOGIN_STATUS";
export const LOGIN_TOKEN = "LOGIN_TOKEN";
export const LOGIN_USER = "LOGIN_USER";
export const DRAWER_OPEN = "DRAWER_OPEN";

// Error Code
export const E_PICKER_CANCELLED = "E_PICKER_CANCELLED";
export const E_PICKER_CANNOT_RUN_CAMERA_ON_SIMULATOR =
  "E_PICKER_CANNOT_RUN_CAMERA_ON_SIMULATOR";
export const E_PERMISSION_MISSING = "E_PERMISSION_MISSING";
export const E_PICKER_NO_CAMERA_PERMISSION = "E_PICKER_NO_CAMERA_PERMISSION";
export const E_USER_CANCELLED = "E_USER_CANCELLED";
export const E_UNKNOWN = "E_UNKNOWN";
export const E_DEVELOPER_ERROR = "E_DEVELOPER_ERROR";
export const TIMEOUT_NETWORK = "ECONNABORTED"; // request service timeout
export const NOT_CONNECT_NETWORK = "NOT_CONNECT_NETWORK";

export const ERROR_CONTACT_ADMINISTRATOR =
  "พบข้อผิดพลาด กรุณาติดต่อผู้ดูแลระบบ";

//////////////// Localization Begin ////////////////
export const NETWORK_CONNECTION_MESSAGE =
  "Cannot connect to server, Please try again.";
export const NETWORK_TIMEOUT_MESSAGE =
  "A network timeout has occurred, Please try again.";
export const UPLOAD_PHOTO_FAIL_MESSAGE =
  "An error has occurred. The photo was unable to upload.";
