import * as React from "react";
import {
  Grid,
  Paper,
  TextField,
  Button,
  Box,
  FormGroup,
  FormControlLabel,
  Switch,
  Avatar,
  InputAdornment,
  IconButton,
} from "@mui/material";
import { makeStyles, useTheme } from "@mui/styles";
import Breadrumb from "../template/Breadcrumb";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { httpClient } from "../../utils/HttpClient";
import { API, ERROR_CONTACT_ADMINISTRATOR, LOGIN_TOKEN } from "../../Constants";
import { useFormik } from "formik";
import * as yup from "yup";
import Chip from "@mui/material/Chip";
import InputFolder from "../form/InputFolder";
import Callback from "../snackbar/Callback";
import Confirm from "../disalog/Confirm";

import * as userActions from "../../actions/user.action";
// Icon B ---------------
import UserGoupSelect from "../disalog/UserGoupSelect";
import SaveAsIcon from "@mui/icons-material/SaveAs";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";

// Icon E ---------------
const useStyle = makeStyles((theme) => ({
  formInput: {
    paddingRight: 10,
    marginBottom: 20,
    width: "100%",
  },
  disabled: {
    background: theme.palette.light.main,
  },
}));

// --------------------------------------------- Default Function
function UserEdit(props) {
  let { u_id } = useParams();
  const navigate = useNavigate();
  u_id = parseInt(u_id);
  const [UserInfo, setUserInfo] = React.useState({
    u_id: 0,
    u_name: "",
    u_username: "",
  });

  const [CallbackData, setCallbackData] = React.useState({
    open: false,
    type: "success",
    message: "",
  });

  const initConfirmData = {
    open: false,
    data: {
      type: "delete",
      title: "title",
      message: "message",
    },
    returnData: { key: "", value: null },
    confirmed: false,
  };
  const [ConfirmData, setConfirmData] = React.useState(initConfirmData);

  const theme = useTheme();
  const classes = useStyle();
  const location = useLocation();

  const [GroupSelectOpen, setGroupSelectOpen] = React.useState(false);

  const pageInfo = {
    name: UserInfo.u_name,
    links: [
      {
        name: "ผู้ใช้งานทั้งหมด",
        path: "/users",
      },
      {
        name: UserInfo.u_name,
        path: "/users/" + UserInfo.u_id,
      },
    ],
  };

  const LoadUserInfo = (props) => {
    httpClient
      .get(`${API.URL}/auth/user/${u_id}`, {
        headers: {
          authorization: localStorage.getItem(LOGIN_TOKEN),
        },
      })
      .then((res) => {
        if (res.data.message) {
          setUserInfo(res.data.message);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const updateUser = async (dataObj) => {
    if (dataObj.u_password) {
      if (!dataObj.re_u_password) {
        setCallbackData({
          ...CallbackData,
          open: true,
          type: "error",
          message: "กรุณายืนยันรหัสผ่าน.",
        });
        return;
      }
      if (dataObj.u_password !== dataObj.re_u_password) {
        setCallbackData({
          ...CallbackData,
          open: true,
          type: "error",
          message: "รหัสผ่านทั้งสองช่องไม่ตรงกัน.",
        });
        return;
      }
    }

    httpClient
      .post(`${API.URL}/auth/user/update`, dataObj, {
        headers: {
          authorization: localStorage.getItem(LOGIN_TOKEN),
        },
      })
      .then((res) => {
        if (res.data.status === "success") {
          setCallbackData({
            ...CallbackData,
            open: true,
            type: "success",
            message: res.data.message,
          });
          navigate("/users");
        } else {
          setCallbackData({
            ...CallbackData,
            open: true,
            type: "error",
            message: "Error !",
          });
          console.error(res.data.message);
        }
      })
      .catch((err) => {
        setCallbackData({
          ...CallbackData,
          open: true,
          type: "error",
          message: err.data.message,
        });
        console.log(err);
      });
  };

  const deleteUser = async (u_id) => {
    const res = await userActions.deleteUser([u_id]);

    if (res.data.status === "success") {
      setCallbackData({
        ...CallbackData,
        open: true,
        type: "success",
        message: res.data.message,
      });
      navigate("/users");
    } else {
      console.error(res.data);
      setCallbackData({
        ...CallbackData,
        open: true,
        type: "error",
        message: ERROR_CONTACT_ADMINISTRATOR,
      });
    }
  };

  React.useEffect(() => {
    LoadUserInfo();
    console.log(location.pathname);
  }, []);

  const validationSchema = yup.object({
    u_name: yup
      .string("Enter your name")
      .min(2, "ชื่อผู้ใช้งานสั้นเกินไป Require 2+")
      .required("name is required"),
    u_username: yup
      .string("Enter your Username")
      .min(2, "min 2 digit.")
      .required("required"),
  });

  const formik = useFormik({
    initialValues: UserInfo,
    enableReinitialize: true,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      updateUser(values);
    },
  });

  return (
    <div>
      <Breadrumb links={pageInfo.links} />
      <Paper elevation={5} sx={{ padding: 2 }}>
        <h1
          style={{ paddingBottom: "15px", borderBottom: "1px solid #e7e7e7" }}
        >
          {u_id !== 0
            ? `แก้ไขผู้ใช้งาน : ${UserInfo.u_name}`
            : "สร้างผู้ใช้งานใหม่"}
        </h1>
        {/* <p>{JSON.stringify(UserInfo)}</p> */}

        <Box
          component="form"
          autoComplete="new-password"
          onSubmit={formik.handleSubmit}
          sx={{ my: 4 }}
        >
          <Grid container>
            <Grid item xs={12} md={6}>
              <TextField
                size="small"
                fullWidth
                id="u_name"
                name="u_name"
                label="ชื่อผู้ใช้งาาน"
                value={formik.values.u_name}
                onChange={formik.handleChange}
                error={formik.touched.u_name && Boolean(formik.errors.u_name)}
                helperText={formik.touched.u_name && formik.errors.u_name}
                className={classes.formInput}
                autoComplete="fullname"
              />
            </Grid>
            <Grid item md={6}>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Switch
                      id="u_status"
                      name="u_status"
                      checked={formik.values.u_status === 1}
                      value={formik.values.u_status}
                      onChange={(event, checked) => {
                        formik.setFieldValue("u_status", checked ? 1 : 0);
                      }}
                      color="theme"
                    />
                  }
                  label="เปิดใช้งาน"
                />
              </FormGroup>
            </Grid>
            <Grid item sm={12} md={4}>
              <TextField
                size="small"
                fullWidth
                id="u_username"
                name="u_username"
                label="บัญชีผูใช้"
                value={formik.values.u_username}
                onChange={formik.handleChange}
                error={
                  formik.touched.u_username && Boolean(formik.errors.u_username)
                }
                helperText={
                  formik.touched.u_username && formik.errors.u_username
                }
                className={classes.formInput}
              />
            </Grid>
            <Grid item sm={12} md={4}>
              <TextField
                id="u_password"
                name="u_password"
                type="password"
                label={u_id != 0 ? "เปลี่ยนรหัสผ่าน" : "รหัสผ่าน"}
                defaultValue=""
                size="small"
                onChange={formik.handleChange}
                className={classes.formInput}
                autoComplete="off"
              />
            </Grid>
            <Grid item sm={12} md={4}>
              <TextField
                disabled={
                  formik.values.u_password === undefined ||
                  formik.values.u_password === ""
                }
                fullWidth
                id="re_u_password"
                name="re_u_password"
                type="password"
                label="ยืนยันรหัสผ่าน"
                defaultValue=""
                size="small"
                onChange={formik.handleChange}
                className={`${classes.formInput} ${
                  formik.values.u_password === undefined ||
                  formik.values.u_password === ""
                    ? classes.disabled
                    : ""
                }`}
                autoComplete="new-password"
              />
            </Grid>
            <Grid item sm={4}>
              <InputFolder
                label="กลุ่มผู้ใช้งาน"
                type="text"
                value={formik.values.uc_name}
                handleClick={() => {
                  setGroupSelectOpen(true);
                }}
                style={{ paddingRight: 10 }}
              />
            </Grid>
          </Grid>

          <Grid container sx={{ my: 2 }}>
            <Grid item sm={12}>
              <Chip
                avatar={
                  <Avatar
                    style={{
                      width: "max-content",
                      borderRadius: 999,
                      padding: "0px 8px",
                      fontWeight: "bold",
                    }}
                  >
                    Last login
                  </Avatar>
                }
                label={formik.values.u_last_login}
                sx={{ mr: 2 }}
              ></Chip>
              <Chip
                avatar={
                  <Avatar
                    style={{
                      width: "max-content",
                      borderRadius: 999,
                      padding: "0px 8px",
                      fontWeight: "bold",
                    }}
                  >
                    Create at
                  </Avatar>
                }
                label={formik.values.u_create}
              ></Chip>
            </Grid>
          </Grid>

          <Grid container justifyContent="space-between">
            <Grid item sm={2}>
              <Button
                fullWidth
                variant="contained"
                color="danger"
                onClick={() => {
                  setConfirmData({
                    ...ConfirmData,
                    open: true,
                    data: {
                      type: "delete",
                      title: "ลบผู้ใช้งาน",
                      message: `ท่านต้องการลบผู้ใช้าน <strong sx={{color:'text.danger'}}>${UserInfo.u_name}</strong> ออกจากระบบหรือไม่...`,
                    },
                    returnData: { key: "userDelete", value: UserInfo.u_id },
                  });
                }}
              >
                <DeleteForeverIcon /> ลบผู้ใช้งาน
              </Button>
            </Grid>
            <Grid item sm={3}>
              <Button
                fullWidth
                type="submit"
                variant="contained"
                color="success"
              >
                <SaveAsIcon sx={{ mr: 2 }} /> บันทึกข้อมูล
              </Button>
            </Grid>
          </Grid>
        </Box>
        <UserGoupSelect
          open={GroupSelectOpen}
          handleClose={() => {
            setGroupSelectOpen(false);
          }}
          selected={formik.values.u_group}
          returnData={(e) => {
            formik.setFieldValue("uc_name", e.uc_name);
            formik.setFieldValue("u_group", e.uc_id);
            setGroupSelectOpen(false);
          }}
        />
      </Paper>
      <Callback
        {...CallbackData}
        close={() => {
          setCallbackData({ ...CallbackData, open: false });
        }}
      />
      <Confirm
        {...ConfirmData}
        handleClose={() => {
          setConfirmData(initConfirmData);
        }}
        confirmed={(e) => {
          if (ConfirmData.returnData.key === "userDelete") {
            deleteUser(ConfirmData.returnData.value);
          }
          setConfirmData(initConfirmData);
        }}
      />
    </div>
  );
}

export default UserEdit;
