import { APP } from "../Constants";

const initialState = {
  DrawerOpen: true,
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = initialState, { type }) => {
  switch (type) {
    case APP.DrawerToggleOpen:
      return { ...state, DrawerOpen: true };
    case APP.DrawerToggleClose:
      return { ...state, DrawerOpen: false };
    default:
      return state;
  }
};
