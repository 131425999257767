import React from "react";
import Header from "./components/template/Header";
import Menu from "./components/template/Menu";
// Pages
import Login from "./components/pages/Login";
import Home from "./components/pages/Home";
import ImageFolder from "./components/pages/image/ImageFolder.jsx";
import VdoFolder from "./components/pages/vdo/VdoFolder.jsx";

import PdfManage from "./components/pages/pdf/PdfManage";
import CategoryList from "./components/pages/CategoryList";
import Users from "./components/pages/Users";
import UserEdit from "./components/pages/UserEdit";
import PageNotFound from "./components/pages/404";
import FolderList from "./components/pages/FolderList";
import DataGrid from "./components/pages/DataGrid";
//
import { ThemeProvider } from "@mui/material/styles";
import { useSelector } from "react-redux";
import { Box, useMediaQuery } from "@mui/material";
import theme from "./style/theme";

import "./style/index.css";
import * as loginActions from "./actions/login.actions";
import * as appActions from "./actions/app.actions";

import { BrowserRouter, Route, Navigate, Routes } from "react-router-dom";
import { useDispatch } from "react-redux";
import PdfEdit from "./components/pages/pdf/PdfEdit";
import CategoryMedia from "./components/pages/CategoryMedia";

const PrivateRoute = ({ children }) => {
  const authed = loginActions.checkLogin();
  return authed !== false ? children : <Navigate to="/login" />;
};

const PublicRoute = ({ children }) => {
  const authed = loginActions.checkLogin();
  return authed !== true ? children : <Navigate to="/home" />;
};
// ---------------------------------------------
function App() {
  const dispatch = useDispatch();
  const loginReducer = useSelector(({ loginReducer }) => loginReducer);
  const appReducer = useSelector(({ appReducer }) => appReducer);
  const screenXs = useMediaQuery(theme.breakpoints.down("sm"));

  React.useEffect(() => {
    // Login Set First
    if (loginActions.checkLogin() && loginReducer.result === null) {
      dispatch(loginActions.setLogin());
    }
    // Drawer Set First
    dispatch(appActions.drawerFirstSet());
  }, []);

  let contentStyle = {
    marginTop: 65,
    padding: 15,
  };

  if (appReducer.DrawerOpen && loginReducer.result && !loginReducer.error) {
    contentStyle = {
      ...contentStyle,
      paddingLeft: screenXs === true ? 15 : contentStyle.padding + 240,
      transition: "all 0.15s ease-in-out",
    };
  } else {
    contentStyle = {
      ...contentStyle,
      paddingLeft: 15,
      transition: "all 0.2s ease-in-out",
    };
  }

  return (
    <ThemeProvider theme={theme}>
      <BrowserRouter className="App">
        {loginReducer.result && !loginReducer.error ? <Header /> : ""}
        {loginReducer.result && !loginReducer.error ? <Menu /> : ""}

        <Box style={contentStyle}>
          <Routes>
            <Route
              path="/datagrid"
              element={
                <PrivateRoute>
                  <DataGrid />
                </PrivateRoute>
              }
            />
            <Route
              path="/login"
              element={
                <PublicRoute>
                  <Login />
                </PublicRoute>
              }
            />
            <Route
              path="/home"
              element={
                <PrivateRoute>
                  <Home />
                </PrivateRoute>
              }
            />
            <Route
              path="/:type/folders"
              element={
                <PrivateRoute>
                  <FolderList />
                </PrivateRoute>
              }
            />
            <Route
              path="/image/folders/id/:id"
              element={
                <PrivateRoute>
                  <ImageFolder />
                </PrivateRoute>
              }
            />
            <Route
              path="/pdf/folders/id/:id"
              element={
                <PrivateRoute>
                  <PdfEdit />
                </PrivateRoute>
              }
            />
            <Route
              path="/vdo/folders/id/:id"
              element={
                <PrivateRoute>
                  <VdoFolder />
                </PrivateRoute>
              }
            />

            <Route
              path="/category"
              element={
                <PrivateRoute>
                  <CategoryList />
                </PrivateRoute>
              }
            />
            <Route
              path="/category/:id"
              element={
                <PrivateRoute>
                  <CategoryList />
                </PrivateRoute>
              }
            />
            <Route
              path="/category_media/:id"
              element={
                <PrivateRoute>
                  <CategoryMedia />
                </PrivateRoute>
              }
            />

            <Route
              path="/users"
              element={
                <PrivateRoute>
                  <Users />
                </PrivateRoute>
              }
            />
            <Route
              path="/users/:u_id"
              element={
                <PrivateRoute>
                  <UserEdit />
                </PrivateRoute>
              }
            />

            <Route exact path="/" element={<Navigate to="/home" />} />
            <Route exact path="*" element={<PageNotFound />} />
          </Routes>
        </Box>
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;
