import { API, LOGIN_TOKEN } from "../Constants";
import { httpClient } from "../utils/HttpClient";

export let deleteUser = async (ids = []) => {
  const result = await httpClient.delete(`${API.URL}/auth/user/delete`, {
    data: { ids: ids },
    headers: {
      authorization: localStorage.getItem(LOGIN_TOKEN),
    },
  });

  return result;
};
