import React from "react";
import { Button, Grid, Paper } from "@mui/material";
import Breadrumb from "../template/Breadcrumb";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import { Link } from "react-router-dom";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { httpClient } from "../../utils/HttpClient";
import { API, ERROR_CONTACT_ADMINISTRATOR, LOGIN_TOKEN } from "../../Constants";
import Callback from "../snackbar/Callback";
import Confirm from "../disalog/Confirm";
import * as userActions from "../../actions/user.action";

// Icon B ---------------
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
// Icon E ---------------

export default function Users() {
  const pageInfo = {
    name: "ผู้ใช้งานทั้งหมด",
    links: [
      {
        name: "ผู้ใช้งานทั้งหมด",
        path: "/users",
      },
    ],
  };

  const [CallbackData, setCallbackData] = React.useState({
    open: false,
    type: "success",
    message: "",
  });

  const [Users, setUsers] = React.useState([]);
  const initConfirmData = {
    open: false,
    data: {
      type: "delete",
      title: "title",
      message: "message",
    },
    returnData: { key: "", value: null },
    confirmed: false,
  };
  const [ConfirmData, setConfirmData] = React.useState(initConfirmData);

  // Functions B-------------------------
  const loadUserInfo = (u_id = 0) => {
    httpClient
      .get(`${API.URL}/auth/user/all`, {
        headers: { authorization: localStorage.getItem(LOGIN_TOKEN) },
      })
      .then((res) => {
        setUsers(res.data.message);
      })
      .catch((err) => {
        Callback({ open: true, type: "error", message: err });
      });
  };

  const deleteUser = async (index) => {
    const u_id = await Users[index].u_id;
    const res = await userActions.deleteUser([u_id]);

    if (res.data.status === "success") {
      setCallbackData({
        ...CallbackData,
        open: true,
        type: "success",
        message: res.data.message,
      });
      loadUserInfo();
    } else {
      console.error(res.data);
      setCallbackData({
        ...CallbackData,
        open: true,
        type: "error",
        message: ERROR_CONTACT_ADMINISTRATOR,
      });
    }
  };

  React.useEffect(() => {
    loadUserInfo();
  }, []);

  // Functions E-------------------------
  return (
    <div>
      <Breadrumb links={pageInfo.links} />

      <Paper elevation={5} sx={{ padding: 2 }}>
        <h2>
          ผู้ใช้งานทั้งหมด
          <Button
            variant="contained"
            color="info"
            sx={{ ml: 2, borderRadius: "50px" }}
            startIcon={<AddCircleOutlineIcon />}
            component={Link}
            to="/users/0"
          >
            เพิ่มผู้ใช้งานใหม่
          </Button>
        </h2>

        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell align="center">#</TableCell>
                <TableCell>ชื่อผู้ใช้งาน</TableCell>
                <TableCell>ยูสเซอร์</TableCell>
                <TableCell align="center">ระดับผู้ใช้งาน</TableCell>
                <TableCell align="center">สถานะ</TableCell>
                <TableCell align="center">แก้ไข</TableCell>
                <TableCell align="center">ลบ</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {Users.map((user, index) => (
                <TableRow
                  key={user.u_id}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell>{index + 1}</TableCell>
                  <TableCell>
                    <strong>{user.u_name}</strong>
                  </TableCell>
                  <TableCell>{user.u_username}</TableCell>
                  <TableCell align="center">{user.uc_name}</TableCell>
                  <TableCell align="center">{user.u_status}</TableCell>
                  <TableCell align="center">
                    <Button
                      variant="contained"
                      color="primary"
                      sx={{ borderRadius: 999 }}
                      component={Link}
                      to={`/users/${user.u_id}`}
                    >
                      <ManageAccountsIcon />
                    </Button>
                  </TableCell>
                  <TableCell align="center">
                    <Button
                      variant="contained"
                      color="danger"
                      sx={{ color: "white", borderRadius: 999 }}
                      onClick={() => {
                        setConfirmData({
                          ...ConfirmData,
                          open: true,
                          data: {
                            type: "delete",
                            title: "ลบผู้ใช้งาน",
                            message: `ท่านต้องการลบผู้ใช้าน <strong sx={{color:'text.danger'}}>${Users[index].u_name}</strong> ออกจากระบบหรือไม่...`,
                          },
                          returnData: { key: "userDelete", value: index },
                        });
                      }}
                    >
                      <DeleteForeverIcon />
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
      <Confirm
        {...ConfirmData}
        handleClose={() => {
          setConfirmData(initConfirmData);
        }}
        confirmed={(e) => {
          if (ConfirmData.returnData.key === "userDelete") {
            deleteUser(ConfirmData.returnData.value);
          }
          setConfirmData(initConfirmData);
        }}
      />
      <Callback
        {...CallbackData}
        close={() => {
          setCallbackData({ ...CallbackData, open: false });
        }}
      />
    </div>
  );
}
