import { APP, DRAWER_OPEN } from "../Constants";

export const setDrawerOpen = () => ({
  type: APP.DrawerToggleOpen,
});

export const setDrawerClose = () => ({
  type: APP.DrawerToggleClose,
});

export const drawerOpen = () => {
  console.log("Run : drawerOpen");
  return (dispatch) => {
    localStorage.setItem(DRAWER_OPEN, true);
    dispatch(setDrawerOpen());
  };
};
export const drawerClose = () => {
  return (dispatch) => {
    localStorage.setItem(DRAWER_OPEN, false);
    dispatch(setDrawerClose());
  };
};

export const drawerFirstSet = () => {
  const check = localStorage.getItem(DRAWER_OPEN);
  if (check === null || JSON.parse(check) === true) {
    return (dispatch) => {
      localStorage.setItem(DRAWER_OPEN, true);
      dispatch(setDrawerOpen());
    };
  } else {
    return (dispatch) => {
      localStorage.setItem(DRAWER_OPEN, false);
      dispatch(setDrawerClose());
    };
  }
};
