import {
  LOGIN_FAILED,
  LOGIN_FETCHING,
  LOGIN_SUCCESS,
  LOGOUT,
} from "../Constants";

const initialState = {
  result: null,
  isFetching: false,
  error: false,
  user: null,
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = initialState, { type, payload }) => {
  switch (type) {
    case LOGIN_FETCHING:
      return {
        ...state,
        isFetching: true,
        error: false,
        result: null,
        user: null,
      };
    case LOGIN_FAILED:
      return {
        ...state,
        isFetching: false,
        error: true,
        result: payload,
        user: null,
      };
    case LOGIN_SUCCESS:
      return {
        ...state,
        isFetching: false,
        error: false,
        result: "logedin",
        user: payload,
      };
    case LOGOUT:
      return initialState;
    default:
      return state;
  }
};
