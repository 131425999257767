import React, { useEffect, useState } from "react";
import {
  Avatar,
  Dialog,
  DialogTitle,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
} from "@mui/material";

import { blue } from "@mui/material/colors";
import { httpClient } from "../../utils/HttpClient";
import { API, LOGIN_TOKEN } from "../../Constants";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
// Icon
import PersonIcon from "@mui/icons-material/Person";
import CheckIcon from "@mui/icons-material/Check";

function UserGoupSelect({ open, handleClose, selected, returnData }) {
  const theme = useTheme();
  const [Groups, setGroups] = useState([]);
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  const loadGroups = () => {
    httpClient
      .get(`${API.URL}/auth/user/group/0?public=1`, {
        headers: {
          authorization: localStorage.getItem(LOGIN_TOKEN),
        },
      })
      .then((res) => {
        setGroups(res.data.message);
      })
      .catch((err) => {
        console.log(err.data.message);
      });
  };
  useEffect(() => {
    loadGroups();
  }, []);

  return (
    <Dialog
      open={open}
      onBackdropClick={handleClose}
      fullScreen={fullScreen}
      fullWidth={true}
      maxWidth="sm"
    >
      <DialogTitle sx={{ background: "#f1f1f1" }}>
        เลือกกลุ่มผู้ใช้งาน
      </DialogTitle>
      <List sx={{ pt: 0 }}>
        {Groups.map((group) => (
          <ListItem
            disabled={selected === group.uc_id}
            button
            onClick={() => {
              returnData(group);
            }}
            key={group.uc_id}
          >
            <ListItemAvatar>
              <Avatar sx={{ bgcolor: blue[100], color: blue[600] }}>
                <PersonIcon />
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              primary={
                selected === group.uc_id ? (
                  <React.Fragment>
                    {group.uc_name}
                    <CheckIcon sx={{ ml: 2 }} />
                  </React.Fragment>
                ) : (
                  group.uc_name
                )
              }
            />
          </ListItem>
        ))}
      </List>
    </Dialog>
  );
}

export default UserGoupSelect;
