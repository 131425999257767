import jwtDecode from "jwt-decode";

import {
  API,
  LOGIN_FAILED,
  LOGIN_FETCHING,
  LOGIN_STATUS,
  LOGIN_SUCCESS,
  LOGIN_TOKEN,
  LOGIN_USER,
  LOGOUT,
} from "../Constants";
import { httpClient } from "../utils/HttpClient";

export const setStateToFetching = () => ({
  type: LOGIN_FETCHING,
});

export const setStateToSuccess = (payload) => ({
  type: LOGIN_SUCCESS,
  payload,
});

export const setStateToFailed = (payload) => ({
  type: LOGIN_FAILED,
  payload,
});

export const setStateToLogout = () => ({
  type: LOGOUT,
});

export const login = (username, password, navigate) => {
  return async (dispatch) => {
    dispatch(setStateToFetching);
    let result = await httpClient.post(API.LOGIN, {
      u_username: username,
      u_password: password,
    });
    if (result.status === 200 && result.data.status === "success") {
      dispatch(setStateToSuccess(result.data.message.user));
      localStorage.setItem(
        LOGIN_USER,
        JSON.stringify(result.data.message.user)
      );
      localStorage.setItem(LOGIN_STATUS, "true");
      localStorage.setItem(LOGIN_TOKEN, result.data.message.token);
      navigate("/home");
    } else {
      dispatch(setStateToFailed(result.data.message));
      localStorage.removeItem(LOGIN_STATUS, false);
      localStorage.removeItem(LOGIN_TOKEN);
    }
  };
};

export const setLogin = () => {
  return (dispatch) => {
    // Check token expire
    const tokenExpire = jwtDecode(localStorage.getItem(LOGIN_TOKEN)).exp;
    const currentTime = Math.floor(Date.now() / 1000, 0);
    if (tokenExpire < currentTime) {
      localStorage.removeItem(LOGIN_STATUS);
      localStorage.removeItem(LOGIN_TOKEN);
      localStorage.removeItem(LOGIN_USER);
      dispatch(setStateToLogout());
    } else {
      dispatch(setStateToSuccess(localStorage.getItem(LOGIN_USER)));
    }
  };
};

export const checkLogin = () => {
  let data = false;
  if (localStorage.getItem(LOGIN_STATUS) !== null) {
    data = JSON.parse(localStorage.getItem(LOGIN_STATUS));
  }
  return data;
};

export const logout = (navigate) => {
  return (dispatch) => {
    dispatch(setStateToLogout());
    localStorage.removeItem(LOGIN_STATUS);
    localStorage.removeItem(LOGIN_TOKEN);
    localStorage.removeItem(LOGIN_USER);
    navigate("/login");
  };
};
