import React from "react";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
// { open:false, type:'success', message:"" }
export default function Callback({
  open = false,
  close,
  type = "success",
  message = "",
  duration = 6000,
}) {
  const [SnackOpen, setSnackOpen] = React.useState(open);

  React.useEffect(() => {
    setSnackOpen(open);
  }, [open]);

  return (
    <div>
      <Snackbar
        open={SnackOpen}
        autoHideDuration={duration}
        onClose={close}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      >
        <Alert onClose={close} severity={type} sx={{ width: "100%" }}>
          {message}
        </Alert>
      </Snackbar>
    </div>
  );
}
