import * as React from "react";
import { emphasize, styled } from "@mui/material/styles";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Chip from "@mui/material/Chip";
import HomeIcon from "@mui/icons-material/Home";
import PersonPinCircleIcon from "@mui/icons-material/PersonPinCircle";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Link } from "react-router-dom";

const StyledBreadcrumb = styled(Chip)(({ theme }) => {
  const backgroundColor = "#ebebeb";
  return {
    backgroundColor,
    height: theme.spacing(3),
    color: theme.palette.text.primary,
    fontWeight: theme.typography.fontWeightRegular,
    cursor: "pointer",
    "&:hover, &:focus": {
      backgroundColor: emphasize(backgroundColor, 0.06),
    },
    "&:active": {
      boxShadow: theme.shadows[1],
      backgroundColor: emphasize(backgroundColor, 0.12),
    },
    "&.lastBreadcrumb": {
      background: theme.palette.theme.main,
      color: "#FFF",
      cursor: "default",
    },
  };
}); // TypeScript only: need a type cast here because https://github.com/Microsoft/TypeScript/issues/26591

export default function CustomizedBreadcrumbs({ links = [] }) {
  return (
    <div
      role="presentation"
      style={{ padding: "15px", paddingLeft: "0px", paddingTop: 0 }}
    >
      <Breadcrumbs aria-label="breadcrumb">
        <StyledBreadcrumb
          component={Link}
          to="/home"
          label="หน้าแรก"
          icon={<HomeIcon fontSize="small" />}
        />

        {links.map((item, index) =>
          links.length - 1 !== index ? (
            <StyledBreadcrumb
              component={Link}
              to={item.path}
              label={item.name}
              key={index}
            />
          ) : (
            <StyledBreadcrumb
              label={item.name}
              key={index}
              className="lastBreadcrumb"
              avatar={<PersonPinCircleIcon sx={{ color: "#fff!important" }} />}
            />
          )
        )}
      </Breadcrumbs>
    </div>
  );
}
