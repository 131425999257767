import { createTheme, responsiveFontSizes } from "@mui/material/styles";
import { red, amber, indigo, teal, blueGrey, cyan } from "@mui/material/colors";

const themeColor = "#005c9c";

const theme = responsiveFontSizes(
  createTheme({
    typography: {
      fontFamily: ["Prompt", "serif"].join(","),
      h1: {
        fontSize: "5rem",
        fontFamily: "Prompt",
      },
      h2: {
        fontSize: "3.5rem",
        fontFamily: "Prompt",
        fontStyle: "bold",
      },
      h3: {
        fontSize: "2.5rem",
        fontFamily: "Prompt",
      },
    },
    palette: {
      theme: {
        main: themeColor,
        contrastText: "#FFF",
      },
      primary: {
        main: indigo[800],
        light: indigo[300],
        contrastText: "#FFF",
      },
      success: {
        main: teal[500],
        light: teal[200],
        contrastText: "#FFF",
      },
      danger: {
        main: red[800],
        light: red[300],
        contrastText: "#FFF",
      },
      warning: {
        main: amber[800],
        light: amber[300],
        contrastText: "#FFF",
      },
      light: {
        main: blueGrey[50],
        dark: blueGrey[150],
        contrastText: blueGrey[700],
      },
      secondary: {
        main: blueGrey[500],
        light: blueGrey[200],
        contrastText: "#FFF",
      },
      info: {
        main: cyan[800],
        light: cyan[300],
        contrastText: "#FFF",
      },
      white: "#FFF",
      text: {
        theme: themeColor,
        primary: indigo[800],
        success: teal[500],
        danger: red[800],
        warning: amber[800],
        info: cyan[800],
        secondary: blueGrey[500],
        light: blueGrey[50],
        white: "#FFF",
      },
    },
  })
);

export default theme;
